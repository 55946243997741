const shapes = {
	handle2abb: [
		[2.7956,2.6720,1.7155],
		[5.7043,2.6720,1.7155],
		[5.7043,2.6720,0.0000],
		[2.7956,2.6720,0.0000],
		[2.7956,2.6720,0.1359],
		[2.7956,2.6720,1.5961],
		[2.8525,2.6720,1.5961],
		[2.8525,2.6720,1.4674],
		[2.9812,2.6720,1.5961],
		[3.0421,2.6720,1.5961],
		[2.8525,2.6720,1.4064],
		[2.8525,2.6720,0.1359],
		[3.7461,2.6720,0.1359],
		[3.7461,2.6720,1.4064],
		[3.5565,2.6720,1.5961],
		[3.6174,2.6720,1.5961],
		[3.7461,2.6720,1.4674],
		[3.7461,2.6720,1.5961],
		[3.8030,2.6720,1.5961],
		[3.8030,2.6720,1.4674],
		[3.9317,2.6720,1.5961],
		[3.9927,2.6720,1.5961],
		[3.8030,2.6720,1.4064],
		[3.8030,2.6720,0.1359],
		[4.6969,2.6720,0.1359],
		[4.6969,2.6720,1.4064],
		[4.5072,2.6720,1.5961],
		[4.5682,2.6720,1.5961],
		[4.6969,2.6720,1.4674],
		[4.6969,2.6720,1.5961],
		[4.7538,2.6720,1.5961],
		[4.7538,2.6720,1.4674],
		[4.8825,2.6720,1.5961],
		[4.9434,2.6720,1.5961],
		[4.7538,2.6720,1.4064],
		[4.7538,2.6720,0.1359],
		[5.6474,2.6720,0.1359],
		[5.6474,2.6720,1.4064],
		[5.4578,2.6720,1.5961],
		[5.5187,2.6720,1.5961],
		[5.6474,2.6720,1.4674],
		[5.6474,2.6720,1.5961],
		[2.8525,2.6720,1.5961],
		[2.7956,2.6720,1.5961],
		[2.7956,2.6720,1.7155]
	],
	handle2aba: [
		[2.7958,2.6720,2.1846],
		[2.7958,2.6720,1.7155],
		[2.8344,2.6720,1.7155],
		[2.8344,2.6720,1.7595],
		[3.7524,2.6720,1.7595],
		[3.7524,2.6720,1.7155],
		[3.7910,2.6720,1.7155],
		[3.7910,2.6720,1.7595],
		[4.7089,2.6720,1.7595],
		[4.7089,2.6720,1.7155],
		[4.7475,2.6720,1.7155],
		[4.7475,2.6720,1.7595],
		[5.6655,2.6720,1.7595],
		[5.6655,2.6720,1.7155],
		[5.7041,2.6720,1.7155],
		[5.7041,2.6720,2.1846],
		[5.6655,2.6720,2.1846],
		[5.6655,2.6720,2.1406],
		[4.7475,2.6720,2.1406],
		[4.7475,2.6720,2.1846],
		[4.7089,2.6720,2.1846],
		[4.7089,2.6720,2.1406],
		[3.7910,2.6720,2.1406],
		[3.7910,2.6720,2.1846],
		[3.7524,2.6720,2.1846],
		[3.7524,2.6720,2.1406],
		[2.8344,2.6720,2.1406],
		[2.8344,2.6720,2.1113],
		[5.6655,2.6720,2.1113],
		[5.6655,2.6720,1.7888],
		[5.5982,2.6720,1.7888],
		[5.5982,2.6720,2.1113],
		[5.5710,2.6720,2.1113],
		[5.5710,2.6720,1.7888],
		[5.5037,2.6720,1.7888],
		[5.5037,2.6720,2.1113],
		[5.4765,2.6720,2.1113],
		[5.4765,2.6720,1.7888],
		[5.4092,2.6720,1.7888],
		[5.4092,2.6720,2.1113],
		[5.3819,2.6720,2.1113],
		[5.3819,2.6720,1.7888],
		[5.3147,2.6720,1.7888],
		[5.3147,2.6720,2.1113],
		[5.2874,2.6720,2.1113],
		[5.2874,2.6720,1.7888],
		[5.2201,2.6720,1.7888],
		[5.2201,2.6720,2.1113],
		[5.1929,2.6720,2.1113],
		[5.1929,2.6720,1.7888],
		[5.1256,2.6720,1.7888],
		[5.1256,2.6720,2.1113],
		[5.0984,2.6720,2.1113],
		[5.0984,2.6720,1.7888],
		[5.0311,2.6720,1.7888],
		[5.0311,2.6720,2.1113],
		[5.0038,2.6720,2.1113],
		[5.0038,2.6720,1.7888],
		[4.9366,2.6720,1.7888],
		[4.9366,2.6720,2.1113],
		[4.9093,2.6720,2.1113],
		[4.9093,2.6720,1.7888],
		[4.8421,2.6720,1.7888],
		[4.8421,2.6720,2.1113],
		[4.8148,2.6720,2.1113],
		[4.8148,2.6720,1.7888],
		[4.7475,2.6720,1.7888],
		[4.7475,2.6720,2.1113],
		[4.7089,2.6720,2.1113],
		[4.7089,2.6720,1.7888],
		[4.6417,2.6720,1.7888],
		[4.6417,2.6720,2.1113],
		[4.6144,2.6720,2.1113],
		[4.6144,2.6720,1.7888],
		[4.5471,2.6720,1.7888],
		[4.5471,2.6720,2.1113],
		[4.5199,2.6720,2.1113],
		[4.5199,2.6720,1.7888],
		[4.4526,2.6720,1.7888],
		[4.4526,2.6720,2.1113],
		[4.4254,2.6720,2.1113],
		[4.4254,2.6720,1.7888],
		[4.3581,2.6720,1.7888],
		[4.3581,2.6720,2.1113],
		[4.3309,2.6720,2.1113],
		[4.3309,2.6720,1.7888],
		[4.2636,2.6720,1.7888],
		[4.2636,2.6720,2.1113],
		[4.2363,2.6720,2.1113],
		[4.2363,2.6720,1.7888],
		[4.1691,2.6720,1.7888],
		[4.1691,2.6720,2.1113],
		[4.1418,2.6720,2.1113],
		[4.1418,2.6720,1.7888],
		[4.0745,2.6720,1.7888],
		[4.0745,2.6720,2.1113],
		[4.0473,2.6720,2.1113],
		[4.0473,2.6720,1.7888],
		[3.9800,2.6720,1.7888],
		[3.9800,2.6720,2.1113],
		[3.9528,2.6720,2.1113],
		[3.9528,2.6720,1.7888],
		[3.8855,2.6720,1.7888],
		[3.8855,2.6720,2.1113],
		[3.8582,2.6720,2.1113],
		[3.8582,2.6720,1.7888],
		[3.7910,2.6720,1.7888],
		[3.7910,2.6720,2.1113],
		[3.7524,2.6720,2.1113],
		[3.7524,2.6720,1.7888],
		[3.6851,2.6720,1.7888],
		[3.6851,2.6720,2.1113],
		[3.6579,2.6720,2.1113],
		[3.6579,2.6720,1.7888],
		[3.5906,2.6720,1.7888],
		[3.5906,2.6720,2.1113],
		[3.5633,2.6720,2.1113],
		[3.5633,2.6720,1.7888],
		[3.4961,2.6720,1.7888],
		[3.4961,2.6720,2.1113],
		[3.4688,2.6720,2.1113],
		[3.4688,2.6720,1.7888],
		[3.4015,2.6720,1.7888],
		[3.4015,2.6720,2.1113],
		[3.3743,2.6720,2.1113],
		[3.3743,2.6720,1.7888],
		[3.3070,2.6720,1.7888],
		[3.3070,2.6720,2.1113],
		[3.2798,2.6720,2.1113],
		[3.2798,2.6720,1.7888],
		[3.2125,2.6720,1.7888],
		[3.2125,2.6720,2.1113],
		[3.1852,2.6720,2.1113],
		[3.1852,2.6720,1.7888],
		[3.1180,2.6720,1.7888],
		[3.1180,2.6720,2.1113],
		[3.0907,2.6720,2.1113],
		[3.0907,2.6720,1.7888],
		[3.0234,2.6720,1.7888],
		[3.0234,2.6720,2.1113],
		[2.9962,2.6720,2.1113],
		[2.9962,2.6720,1.7888],
		[2.9289,2.6720,1.7888],
		[2.9289,2.6720,2.1113],
		[2.9017,2.6720,2.1113],
		[2.9017,2.6720,1.7888],
		[2.8344,2.6720,1.7888],
		[2.8344,2.6720,2.1113],
		[2.8344,2.6720,2.1406],
		[2.8344,2.6720,2.1846],
		[2.7958,2.6720,2.1846]
	],
	handle2881: [
		[2.8347,1.7154,2.1406],
		[2.7958,1.7154,2.1406],
		[2.7958,1.7154,1.7155],
		[2.8347,1.7154,1.7155]
	],
	handle287f: [
		[2.8347,1.8111,2.1406],
		[2.7958,1.8111,2.1406],
		[2.7958,1.8111,1.7155],
		[2.8347,1.8111,1.7155]
	],
	handle287e: [
		[2.8347,1.9067,2.1406],
		[2.7958,1.9067,2.1406],
		[2.7958,1.9067,1.7155],
		[2.8347,1.9067,1.7155]
	],
	handle287d: [
		[2.8347,2.0024,2.1406],
		[2.7958,2.0024,2.1406],
		[2.7958,2.0024,1.7155],
		[2.8347,2.0024,1.7155]
	],
	handle287c: [
		[2.8347,2.0980,2.1406],
		[2.7958,2.0980,2.1406],
		[2.7958,2.0980,1.7155],
		[2.8347,2.0980,1.7155]
	],
	handle287b: [
		[2.8347,2.1937,2.1406],
		[2.7958,2.1937,2.1406],
		[2.7958,2.1937,1.7155],
		[2.8347,2.1937,1.7155]
	],
	handle287a: [
		[2.8347,2.2894,2.1406],
		[2.7958,2.2894,2.1406],
		[2.7958,2.2894,1.7155],
		[2.8347,2.2894,1.7155]
	],
	handle2879: [
		[2.8347,2.3850,2.1406],
		[2.7958,2.3850,2.1406],
		[2.7958,2.3850,1.7155],
		[2.8347,2.3850,1.7155]
	],
	handle2878: [
		[2.8347,2.5763,2.1406],
		[2.7958,2.5763,2.1406],
		[2.7958,2.5763,1.7155],
		[2.8347,2.5763,1.7155]
	],
	handle2877: [
		[2.8347,2.4807,2.1406],
		[2.7958,2.4807,2.1406],
		[2.7958,2.4807,1.7155],
		[2.8347,2.4807,1.7155]
	],
	handle286c: [
		[5.7043,1.7154,2.1406],
		[5.6655,1.7154,2.1406],
		[5.6655,1.7154,1.7155],
		[5.7043,1.7154,1.7155]
	],
	handle286a: [
		[5.7043,1.8111,2.1406],
		[5.6655,1.8111,2.1406],
		[5.6655,1.8111,1.7155],
		[5.7043,1.8111,1.7155]
	],
	handle2869: [
		[5.7043,1.9067,2.1406],
		[5.6655,1.9067,2.1406],
		[5.6655,1.9067,1.7155],
		[5.7043,1.9067,1.7155]
	],
	handle2868: [
		[5.7043,2.0024,2.1406],
		[5.6655,2.0024,2.1406],
		[5.6655,2.0024,1.7155],
		[5.7043,2.0024,1.7155]
	],
	handle2867: [
		[5.7043,2.0980,2.1406],
		[5.6655,2.0980,2.1406],
		[5.6655,2.0980,1.7155],
		[5.7043,2.0980,1.7155]
	],
	handle2866: [
		[5.7043,2.1937,2.1406],
		[5.6655,2.1937,2.1406],
		[5.6655,2.1937,1.7155],
		[5.7043,2.1937,1.7155]
	],
	handle2865: [
		[5.7043,2.2894,2.1406],
		[5.6655,2.2894,2.1406],
		[5.6655,2.2894,1.7155],
		[5.7043,2.2894,1.7155]
	],
	handle2864: [
		[5.7043,2.3850,2.1406],
		[5.6655,2.3850,2.1406],
		[5.6655,2.3850,1.7155],
		[5.7043,2.3850,1.7155]
	],
	handle2863: [
		[5.7043,2.5763,2.1406],
		[5.6655,2.5763,2.1406],
		[5.6655,2.5763,1.7155],
		[5.7043,2.5763,1.7155]
	],
	handle2862: [
		[5.7043,2.4807,2.1406],
		[5.6655,2.4807,2.1406],
		[5.6655,2.4807,1.7155],
		[5.7043,2.4807,1.7155]
	],
	handle2342: [
		[0.0000,0.0000,0.0000],
		[0.0000,5.5000,0.0000],
		[8.5000,5.5000,0.0000],
		[8.5000,0.0000,0.0000],
		[0.0000,0.0000,0.0000]
	],
	handle233a: [
		[0.0000,0.0000,0.0000],
		[0.0000,0.0000,5.5000],
		[8.5000,0.0000,5.5000],
		[8.5000,0.0000,0.0000],
		[0.0000,0.0000,0.0000]
	],
	handle2261: [
		[2.8347,1.7154,2.1406],
		[2.7958,1.7154,2.1406],
		[2.7958,2.6720,2.1406],
		[2.8347,2.6720,2.1406]
	],
	handle225f: [
		[5.7043,1.7154,2.1406],
		[5.6655,1.7154,2.1406],
		[5.6655,2.6720,2.1406],
		[5.7043,2.6720,2.1406]
	],
	handle2194: [
		[5.7045,2.6720,1.7155],
		[2.7958,2.6720,1.7155],
		[2.7958,1.7154,1.7155],
		[5.7045,1.7154,1.7155]
	],
	handle2193: [
		[5.7044,2.6720,0.1359],
		[2.7956,2.6720,0.1359],
		[2.7956,1.7154,0.1359],
		[2.9011,1.7154,0.1359],
		[4.2500,1.7154,0.1359],
		[5.5989,1.7154,0.1359],
		[5.6656,1.7154,0.1359],
		[5.7044,1.7154,0.1359]
	],
	handle2186: [
		[5.6046,0.0000,1.8953],
		[5.7472,0.0000,1.8953],
		[5.7472,1.7154,1.8953],
		[5.6046,1.7154,1.8953],
		[5.6046,1.5355,1.8953]
	],
	handle2185: [
		[5.4620,0.0000,2.0752],
		[5.6046,0.0000,2.0752],
		[5.6046,1.7154,2.0752],
		[5.4620,1.7154,2.0752],
		[5.4620,1.5355,2.0752]
	],
	handle2184: [
		[5.3194,0.0000,2.2552],
		[5.4620,0.0000,2.2552],
		[5.4620,1.7154,2.2552],
		[5.3194,1.7154,2.2552],
		[5.3194,1.5355,2.2552]
	],
	handle2183: [
		[5.1768,0.0000,2.4351],
		[5.3194,0.0000,2.4351],
		[5.3194,1.7154,2.4351],
		[5.1768,1.7154,2.4351],
		[5.1768,1.5355,2.4351]
	],
	handle2182: [
		[5.0342,0.0000,2.6150],
		[5.1768,0.0000,2.6150],
		[5.1768,1.7154,2.6150],
		[5.0342,1.7154,2.6150],
		[5.0342,1.5355,2.6150]
	],
	handle2181: [
		[4.8916,0.0000,2.7950],
		[5.0342,0.0000,2.7950],
		[5.0342,1.7154,2.7950],
		[4.8916,1.7154,2.7950],
		[4.8916,1.5355,2.7950]
	],
	handle2180: [
		[4.7491,0.0000,2.9749],
		[4.8916,0.0000,2.9749],
		[4.8916,1.7154,2.9749],
		[4.7491,1.7154,2.9749],
		[4.7491,1.5355,2.9749]
	],
	handle217f: [
		[4.6065,0.0000,3.1548],
		[4.7491,0.0000,3.1548],
		[4.7491,1.7154,3.1548],
		[4.6065,1.7154,3.1548],
		[4.6065,1.5355,3.1548]
	],
	handle217e: [
		[4.4639,0.0000,3.3348],
		[4.6065,0.0000,3.3348],
		[4.6065,1.7154,3.3348],
		[4.4639,1.7154,3.3348],
		[4.4639,1.5355,3.3348]
	],
	handle217d: [
		[4.3213,0.0000,3.5147],
		[4.4639,0.0000,3.5147],
		[4.4639,1.7154,3.5147],
		[4.3213,1.7154,3.5147]
	],
	handle217c: [
		[4.1787,0.0000,3.6946],
		[4.3213,0.0000,3.6946],
		[4.3213,1.7154,3.6946],
		[4.1787,1.7154,3.6946]
	],
	handle217b: [
		[4.0361,0.0000,3.5147],
		[4.1787,0.0000,3.5147],
		[4.1787,1.7154,3.5147],
		[4.0361,1.7154,3.5147]
	],
	handle217a: [
		[3.8935,0.0000,3.3348],
		[4.0361,0.0000,3.3348],
		[4.0361,1.5355,3.3348],
		[4.0361,1.7154,3.3348],
		[3.8935,1.7154,3.3348]
	],
	handle2179: [
		[3.7509,0.0000,3.1548],
		[3.8935,0.0000,3.1548],
		[3.8935,1.5355,3.1548],
		[3.8935,1.7154,3.1548],
		[3.7509,1.7154,3.1548]
	],
	handle2178: [
		[3.6084,0.0000,2.9749],
		[3.7509,0.0000,2.9749],
		[3.7509,1.5355,2.9749],
		[3.7509,1.7154,2.9749],
		[3.6084,1.7154,2.9749]
	],
	handle2177: [
		[3.4658,0.0000,2.7950],
		[3.6084,0.0000,2.7950],
		[3.6084,1.5355,2.7950],
		[3.6084,1.7154,2.7950],
		[3.4658,1.7154,2.7950]
	],
	handle2176: [
		[3.3232,0.0000,2.6150],
		[3.4658,0.0000,2.6150],
		[3.4658,1.5355,2.6150],
		[3.4658,1.7154,2.6150],
		[3.3232,1.7154,2.6150]
	],
	handle2175: [
		[3.1806,0.0000,2.4351],
		[3.3232,0.0000,2.4351],
		[3.3232,1.5355,2.4351],
		[3.3232,1.7154,2.4351],
		[3.1806,1.7154,2.4351]
	],
	handle2174: [
		[3.0380,0.0000,2.2552],
		[3.1806,0.0000,2.2552],
		[3.1806,1.5355,2.2552],
		[3.1806,1.7154,2.2552],
		[3.0380,1.7154,2.2552]
	],
	handle2173: [
		[2.8954,0.0000,2.0752],
		[3.0380,0.0000,2.0752],
		[3.0380,1.5355,2.0752],
		[3.0380,1.7154,2.0752],
		[2.8954,1.7154,2.0752]
	],
	handle2172: [
		[2.7528,0.0000,1.8953],
		[2.8954,0.0000,1.8953],
		[2.8954,1.5355,1.8953],
		[2.8954,1.7154,1.8953],
		[2.7528,1.7154,1.8953]
	],
	handle20fb: [
		[2.0781,1.7154,1.3833],
		[2.3597,1.7154,1.3833],
		[2.3597,1.7154,0.4196],
		[1.7964,1.7154,0.4196],
		[1.7964,1.7154,1.3833],
		[2.0781,1.7154,1.3833],
		[2.0781,1.7154,1.2927],
		[2.0162,1.7154,1.2927],
		[2.0162,1.7154,1.1115],
		[2.0781,1.7154,1.1115],
		[2.0781,1.7154,1.0923],
		[2.0162,1.7154,1.0923],
		[2.0162,1.7154,0.9111],
		[2.0781,1.7154,0.9111],
		[2.0781,1.7154,0.8918],
		[2.0162,1.7154,0.8918],
		[2.0162,1.7154,0.7106],
		[2.0781,1.7154,0.7106],
		[2.0781,1.7154,0.6914],
		[2.0162,1.7154,0.6914],
		[2.0162,1.7154,0.5102],
		[1.9969,1.7154,0.5102],
		[1.9969,1.7154,1.2927],
		[1.8733,1.7154,1.2927],
		[1.8733,1.7154,1.1115],
		[1.9969,1.7154,1.1115],
		[1.9969,1.7154,1.0923],
		[1.8733,1.7154,1.0923],
		[1.8733,1.7154,0.9111],
		[1.9969,1.7154,0.9111],
		[1.9969,1.7154,0.8918],
		[1.8733,1.7154,0.8918],
		[1.8733,1.7154,0.7106],
		[1.9969,1.7154,0.7106],
		[1.9969,1.7154,0.6914],
		[1.8733,1.7154,0.6914],
		[1.8733,1.7154,0.5102],
		[2.1399,1.7154,0.5102],
		[2.1399,1.7154,0.6914],
		[2.0781,1.7154,0.6914],
		[2.0781,1.7154,0.7106],
		[2.1399,1.7154,0.7106],
		[2.1399,1.7154,0.8918],
		[2.0781,1.7154,0.8918],
		[2.0781,1.7154,0.9111],
		[2.1399,1.7154,0.9111],
		[2.1399,1.7154,1.0923],
		[2.0781,1.7154,1.0923],
		[2.0781,1.7154,1.1115],
		[2.1399,1.7154,1.1115],
		[2.1399,1.7154,1.2927],
		[2.1592,1.7154,1.2927],
		[2.1592,1.7154,0.5102],
		[2.2828,1.7154,0.5102],
		[2.2828,1.7154,0.6914],
		[2.1592,1.7154,0.6914],
		[2.1592,1.7154,0.7106],
		[2.2828,1.7154,0.7106],
		[2.2828,1.7154,0.8918],
		[2.1592,1.7154,0.8918],
		[2.1592,1.7154,0.9111],
		[2.2828,1.7154,0.9111],
		[2.2828,1.7154,1.0923],
		[2.1592,1.7154,1.0923],
		[2.1592,1.7154,1.1115],
		[2.2828,1.7154,1.1115],
		[2.2828,1.7154,1.2927],
		[2.1592,1.7154,1.2927],
		[2.1399,1.7154,1.2927],
		[2.0781,1.7154,1.2927],
		[2.0781,1.7154,1.3833]
	],
	handle20fa: [
		[3.1915,1.7154,1.3833],
		[3.4731,1.7154,1.3833],
		[3.4731,1.7154,0.4196],
		[2.9099,1.7154,0.4196],
		[2.9099,1.7154,1.3833],
		[3.1915,1.7154,1.3833],
		[3.1915,1.7154,1.2927],
		[3.1297,1.7154,1.2927],
		[3.1297,1.7154,1.1115],
		[3.1915,1.7154,1.1115],
		[3.1915,1.7154,1.0923],
		[3.1297,1.7154,1.0923],
		[3.1297,1.7154,0.9111],
		[3.1915,1.7154,0.9111],
		[3.1915,1.7154,0.8918],
		[3.1297,1.7154,0.8918],
		[3.1297,1.7154,0.7106],
		[3.1915,1.7154,0.7106],
		[3.1915,1.7154,0.6914],
		[3.1297,1.7154,0.6914],
		[3.1297,1.7154,0.5102],
		[3.1104,1.7154,0.5102],
		[3.1104,1.7154,1.2927],
		[2.9867,1.7154,1.2927],
		[2.9867,1.7154,1.1115],
		[3.1104,1.7154,1.1115],
		[3.1104,1.7154,1.0923],
		[2.9867,1.7154,1.0923],
		[2.9867,1.7154,0.9111],
		[3.1104,1.7154,0.9111],
		[3.1104,1.7154,0.8918],
		[2.9867,1.7154,0.8918],
		[2.9867,1.7154,0.7106],
		[3.1104,1.7154,0.7106],
		[3.1104,1.7154,0.6914],
		[2.9867,1.7154,0.6914],
		[2.9867,1.7154,0.5102],
		[3.2533,1.7154,0.5102],
		[3.2533,1.7154,0.6914],
		[3.1915,1.7154,0.6914],
		[3.1915,1.7154,0.7106],
		[3.2533,1.7154,0.7106],
		[3.2533,1.7154,0.8918],
		[3.1915,1.7154,0.8918],
		[3.1915,1.7154,0.9111],
		[3.2533,1.7154,0.9111],
		[3.2533,1.7154,1.0923],
		[3.1915,1.7154,1.0923],
		[3.1915,1.7154,1.1115],
		[3.2533,1.7154,1.1115],
		[3.2533,1.7154,1.2927],
		[3.2726,1.7154,1.2927],
		[3.2726,1.7154,0.5102],
		[3.3963,1.7154,0.5102],
		[3.3963,1.7154,0.6914],
		[3.2726,1.7154,0.6914],
		[3.2726,1.7154,0.7106],
		[3.3963,1.7154,0.7106],
		[3.3963,1.7154,0.8918],
		[3.2726,1.7154,0.8918],
		[3.2726,1.7154,0.9111],
		[3.3963,1.7154,0.9111],
		[3.3963,1.7154,1.0923],
		[3.2726,1.7154,1.0923],
		[3.2726,1.7154,1.1115],
		[3.3963,1.7154,1.1115],
		[3.3963,1.7154,1.2927],
		[3.2726,1.7154,1.2927],
		[3.2533,1.7154,1.2927],
		[3.1915,1.7154,1.2927],
		[3.1915,1.7154,1.3833]
	],
	handle20f9: [
		[5.3085,1.7154,1.3833],
		[5.5901,1.7154,1.3833],
		[5.5901,1.7154,0.4196],
		[5.0269,1.7154,0.4196],
		[5.0269,1.7154,1.3833],
		[5.3085,1.7154,1.3833],
		[5.3085,1.7154,1.2927],
		[5.2467,1.7154,1.2927],
		[5.2467,1.7154,1.1115],
		[5.3085,1.7154,1.1115],
		[5.3085,1.7154,1.0923],
		[5.2467,1.7154,1.0923],
		[5.2467,1.7154,0.9111],
		[5.3085,1.7154,0.9111],
		[5.3085,1.7154,0.8918],
		[5.2467,1.7154,0.8918],
		[5.2467,1.7154,0.7106],
		[5.3085,1.7154,0.7106],
		[5.3085,1.7154,0.6914],
		[5.2467,1.7154,0.6914],
		[5.2467,1.7154,0.5102],
		[5.2274,1.7154,0.5102],
		[5.2274,1.7154,1.2927],
		[5.1037,1.7154,1.2927],
		[5.1037,1.7154,1.1115],
		[5.2274,1.7154,1.1115],
		[5.2274,1.7154,1.0923],
		[5.1037,1.7154,1.0923],
		[5.1037,1.7154,0.9111],
		[5.2274,1.7154,0.9111],
		[5.2274,1.7154,0.8918],
		[5.1037,1.7154,0.8918],
		[5.1037,1.7154,0.7106],
		[5.2274,1.7154,0.7106],
		[5.2274,1.7154,0.6914],
		[5.1037,1.7154,0.6914],
		[5.1037,1.7154,0.5102],
		[5.3703,1.7154,0.5102],
		[5.3703,1.7154,0.6914],
		[5.3085,1.7154,0.6914],
		[5.3085,1.7154,0.7106],
		[5.3703,1.7154,0.7106],
		[5.3703,1.7154,0.8918],
		[5.3085,1.7154,0.8918],
		[5.3085,1.7154,0.9111],
		[5.3703,1.7154,0.9111],
		[5.3703,1.7154,1.0923],
		[5.3085,1.7154,1.0923],
		[5.3085,1.7154,1.1115],
		[5.3703,1.7154,1.1115],
		[5.3703,1.7154,1.2927],
		[5.3896,1.7154,1.2927],
		[5.3896,1.7154,0.5102],
		[5.5133,1.7154,0.5102],
		[5.5133,1.7154,0.6914],
		[5.3896,1.7154,0.6914],
		[5.3896,1.7154,0.7106],
		[5.5133,1.7154,0.7106],
		[5.5133,1.7154,0.8918],
		[5.3896,1.7154,0.8918],
		[5.3896,1.7154,0.9111],
		[5.5133,1.7154,0.9111],
		[5.5133,1.7154,1.0923],
		[5.3896,1.7154,1.0923],
		[5.3896,1.7154,1.1115],
		[5.5133,1.7154,1.1115],
		[5.5133,1.7154,1.2927],
		[5.3896,1.7154,1.2927],
		[5.3703,1.7154,1.2927],
		[5.3085,1.7154,1.2927],
		[5.3085,1.7154,1.3833]
	],
	handle20f8: [
		[6.4219,1.7154,1.3833],
		[6.7036,1.7154,1.3833],
		[6.7036,1.7154,0.4196],
		[6.1403,1.7154,0.4196],
		[6.1403,1.7154,1.3833],
		[6.4219,1.7154,1.3833],
		[6.4219,1.7154,1.2927],
		[6.3601,1.7154,1.2927],
		[6.3601,1.7154,1.1115],
		[6.4219,1.7154,1.1115],
		[6.4219,1.7154,1.0923],
		[6.3601,1.7154,1.0923],
		[6.3601,1.7154,0.9111],
		[6.4219,1.7154,0.9111],
		[6.4219,1.7154,0.8918],
		[6.3601,1.7154,0.8918],
		[6.3601,1.7154,0.7106],
		[6.4219,1.7154,0.7106],
		[6.4219,1.7154,0.6914],
		[6.3601,1.7154,0.6914],
		[6.3601,1.7154,0.5102],
		[6.3408,1.7154,0.5102],
		[6.3408,1.7154,1.2927],
		[6.2172,1.7154,1.2927],
		[6.2172,1.7154,1.1115],
		[6.3408,1.7154,1.1115],
		[6.3408,1.7154,1.0923],
		[6.2172,1.7154,1.0923],
		[6.2172,1.7154,0.9111],
		[6.3408,1.7154,0.9111],
		[6.3408,1.7154,0.8918],
		[6.2172,1.7154,0.8918],
		[6.2172,1.7154,0.7106],
		[6.3408,1.7154,0.7106],
		[6.3408,1.7154,0.6914],
		[6.2172,1.7154,0.6914],
		[6.2172,1.7154,0.5102],
		[6.4838,1.7154,0.5102],
		[6.4838,1.7154,0.6914],
		[6.4219,1.7154,0.6914],
		[6.4219,1.7154,0.7106],
		[6.4838,1.7154,0.7106],
		[6.4838,1.7154,0.8918],
		[6.4219,1.7154,0.8918],
		[6.4219,1.7154,0.9111],
		[6.4838,1.7154,0.9111],
		[6.4838,1.7154,1.0923],
		[6.4219,1.7154,1.0923],
		[6.4219,1.7154,1.1115],
		[6.4838,1.7154,1.1115],
		[6.4838,1.7154,1.2927],
		[6.5031,1.7154,1.2927],
		[6.5031,1.7154,0.5102],
		[6.6267,1.7154,0.5102],
		[6.6267,1.7154,0.6914],
		[6.5031,1.7154,0.6914],
		[6.5031,1.7154,0.7106],
		[6.6267,1.7154,0.7106],
		[6.6267,1.7154,0.8918],
		[6.5031,1.7154,0.8918],
		[6.5031,1.7154,0.9111],
		[6.6267,1.7154,0.9111],
		[6.6267,1.7154,1.0923],
		[6.5031,1.7154,1.0923],
		[6.5031,1.7154,1.1115],
		[6.6267,1.7154,1.1115],
		[6.6267,1.7154,1.2927],
		[6.5031,1.7154,1.2927],
		[6.4838,1.7154,1.2927],
		[6.4219,1.7154,1.2927],
		[6.4219,1.7154,1.3833]
	],
	handle20e5: [
		[4.4752,1.7154,1.2057],
		[4.4752,1.7154,0.1359],
		[4.0248,1.7154,0.1359],
		[4.0248,1.7154,1.2057],
		[4.4752,1.7154,1.2057]
	],
	handle20dd: [
		[4.2500,1.7154,2.7852],
		[4.4752,1.7154,2.7852],
		[4.4752,1.7154,1.7155],
		[4.0248,1.7154,1.7155],
		[4.0248,1.7154,2.7852],
		[4.2500,1.7154,2.7852],
		[4.2500,1.7154,2.7175],
		[4.1926,1.7154,2.7175],
		[4.1926,1.7154,2.4156],
		[4.1692,1.7154,2.4156],
		[4.1692,1.7154,2.7175],
		[4.1208,1.7154,2.7175],
		[4.1208,1.7154,2.4156],
		[4.1692,1.7154,2.4156],
		[4.1926,1.7154,2.4156],
		[4.1926,1.7154,2.3956],
		[4.1692,1.7154,2.3956],
		[4.1208,1.7154,2.3956],
		[4.1208,1.7154,2.0937],
		[4.1692,1.7154,2.0937],
		[4.1692,1.7154,2.3956],
		[4.1926,1.7154,2.3956],
		[4.1926,1.7154,2.0937],
		[4.3074,1.7154,2.0937],
		[4.3074,1.7154,2.3956],
		[4.1926,1.7154,2.3956],
		[4.1926,1.7154,2.4156],
		[4.3074,1.7154,2.4156],
		[4.3308,1.7154,2.4156],
		[4.3308,1.7154,2.3956],
		[4.3308,1.7154,2.0937],
		[4.3792,1.7154,2.0937],
		[4.3792,1.7154,2.3956],
		[4.3308,1.7154,2.3956],
		[4.3308,1.7154,2.4156],
		[4.3792,1.7154,2.4156],
		[4.3792,1.7154,2.7175],
		[4.3308,1.7154,2.7175],
		[4.3308,1.7154,2.4156],
		[4.3074,1.7154,2.4156],
		[4.3074,1.7154,2.7175],
		[4.2500,1.7154,2.7175],
		[4.2500,1.7154,2.7852]
	],
	handle20dc: [
		[4.4752,1.7154,2.7852],
		[4.4752,1.7154,1.7155],
		[4.5344,1.7154,1.7155],
		[4.5344,1.7154,2.8913],
		[3.9656,1.7154,2.8913],
		[3.9656,1.7154,1.7155],
		[4.0248,1.7154,1.7155],
		[4.0248,1.7154,2.7852],
		[4.4752,1.7154,2.7852]
	],
	handle20d2: [
		[4.4752,1.7154,1.2057],
		[4.4752,1.7154,0.1359],
		[4.5344,1.7154,0.1359],
		[4.5344,1.7154,1.3833],
		[3.9656,1.7154,1.3833],
		[3.9656,1.7154,0.1359],
		[4.0248,1.7154,0.1359],
		[4.0248,1.7154,1.2057],
		[4.4752,1.7154,1.2057],
		[4.4752,1.7154,1.2397],
		[4.0248,1.7154,1.2397],
		[4.0248,1.7154,1.3122],
		[4.4752,1.7154,1.3122],
		[4.4752,1.7154,1.2397],
		[4.4752,1.7154,1.2057]
	],
	handle20d0: [
		[5.5901,1.7154,1.3276],
		[6.1403,1.7154,1.3276],
		[6.1403,1.7154,0.4196],
		[6.7036,1.7154,0.4196],
		[6.7036,1.7154,1.3276],
		[7.1907,1.7154,1.3276],
		[7.1907,1.7154,0.1359],
		[1.3093,1.7154,0.1359],
		[1.3093,1.7154,1.3276],
		[1.7964,1.7154,1.3276],
		[1.7964,1.7154,0.4196],
		[2.3597,1.7154,0.4196],
		[2.3597,1.7154,1.3276],
		[2.9099,1.7154,1.3276],
		[2.9099,1.7154,0.4196],
		[3.4731,1.7154,0.4196],
		[3.4731,1.7154,1.3276],
		[3.9656,1.7154,1.3276],
		[3.9656,1.7154,0.1359],
		[4.5344,1.7154,0.1359],
		[4.5344,1.7154,1.3276],
		[5.0269,1.7154,1.3276],
		[5.0269,1.7154,0.4196],
		[5.5901,1.7154,0.4196],
		[5.5901,1.7154,1.3276],
		[5.5989,1.7154,1.7155],
		[4.5344,1.7154,1.7155],
		[4.5344,1.7154,2.8913],
		[3.9656,1.7154,2.8913],
		[3.9656,1.7154,1.7155],
		[2.9011,1.7154,1.7155],
		[4.2500,1.7154,3.4303],
		[5.5989,1.7154,1.7155],
		[5.5901,1.7154,1.3276]
	],
	handle20c4: [
		[5.8897,1.7154,1.7155],
		[7.2824,1.7154,1.7155],
		[7.2824,1.7154,0.0000],
		[1.2176,1.7154,0.0000],
		[1.2176,1.7154,1.7155],
		[2.6103,1.7154,1.7155],
		[4.2500,1.7154,3.7846],
		[5.8897,1.7154,1.7155],
		[5.5989,1.7154,1.7155],
		[4.2500,1.7154,3.4303],
		[2.9011,1.7154,1.7155],
		[5.5989,1.7154,1.7155],
		[5.8897,1.7154,1.7155],
		[5.8897,1.7154,1.3276],
		[5.5901,1.7154,1.3276],
		[5.5901,1.7154,1.3833],
		[5.0269,1.7154,1.3833],
		[5.0269,1.7154,1.3276],
		[4.5344,1.7154,1.3276],
		[4.5344,1.7154,1.3833],
		[3.9656,1.7154,1.3833],
		[3.9656,1.7154,1.3276],
		[3.4731,1.7154,1.3276],
		[3.4731,1.7154,1.3833],
		[2.9099,1.7154,1.3833],
		[2.9099,1.7154,1.3276],
		[2.3597,1.7154,1.3276],
		[2.3597,1.7154,1.3833],
		[1.7964,1.7154,1.3833],
		[1.7964,1.7154,1.3276],
		[1.3093,1.7154,1.3276],
		[1.3093,1.7154,0.1359],
		[7.1907,1.7154,0.1359],
		[7.1907,1.7154,1.3276],
		[6.7036,1.7154,1.3276],
		[6.7036,1.7154,1.3833],
		[6.1403,1.7154,1.3833],
		[6.1403,1.7154,1.3276],
		[5.8897,1.7154,1.3276],
		[5.8897,1.7154,1.7155]
	],
	handle2068: [
		[1.2176,0.0000,1.7155],
		[7.2824,0.0000,1.7155],
		[7.2824,1.7154,1.7155],
		[1.2176,1.7154,1.7155]
	],
	handle2dd6: [
		[7.2824,0.9379,2.6533],
		[7.2824,0.0000,3.7846],
		[7.2824,0.0000,1.7155]
	],
	handle2dd5: [
		[7.2824,0.9379,2.6533],
		[7.2824,0.0000,1.7155],
		[7.2824,1.7154,1.7155]
	],
	handle2062: [
		[7.2824,1.7154,1.7155],
		[7.2824,0.0000,1.7155],
		[7.2824,0.8577,0.8578]
	],
	handle2063: [
		[7.2824,1.7154,0.0000],
		[7.2824,1.7154,1.7155],
		[7.2824,0.8577,0.8578]
	],
	handle2061: [
		[7.2824,1.7154,0.0000],
		[7.2824,0.8577,0.8578],
		[7.2824,0.0000,0.0000]
	],
	handle2060: [
		[7.2824,0.8577,0.8578],
		[7.2824,0.0000,1.7155],
		[7.2824,0.0000,0.0000]
	],
	handle2d71: [
		[1.2176,0.7449,2.8862],
		[1.2176,1.7154,1.7155],
		[2.6103,1.7154,1.7155],
		[3.5380,0.7449,2.8862],
		[4.9618,0.7449,2.8862],
		[5.8895,1.7154,1.7155],
		[7.2821,1.7154,1.7155],
		[7.2821,0.7449,2.8862],
		[1.2176,0.7449,2.8862]
	],
	handle2d72: [
		[1.2176,0.0001,3.7846],
		[1.2176,0.7449,2.8862],
		[3.5380,0.7449,2.8862],
		[4.2499,0.0001,3.7846],
		[4.9618,0.7449,2.8862],
		[7.2821,0.7449,2.8862],
		[7.2821,0.0001,3.7846],
		[1.2176,0.0001,3.7846],
		[1.2176,0.7449,2.8862]
	],
	handle2b3c: [
		[1.2176,1.7154,1.7155],
		[1.2176,0.0000,1.7155],
		[1.2176,0.8577,0.8578]
	],
	handle2b3d: [
		[1.2176,1.7154,0.0000],
		[1.2176,1.7154,1.7155],
		[1.2176,0.8577,0.8578]
	],
	handle2b3b: [
		[1.2176,1.7154,0.0000],
		[1.2176,0.8577,0.8578],
		[1.2176,0.0000,0.0000]
	],
	handle2b3a: [
		[1.2176,0.8577,0.8578],
		[1.2176,0.0000,1.7155],
		[1.2176,0.0000,0.0000]
	],
	handle2066: [
		[1.2176,0.9379,2.6533],
		[1.2176,0.0000,1.7155],
		[1.2176,1.7154,1.7155]
	],
	handle2067: [
		[1.2176,0.9379,2.6533],
		[1.2176,0.0000,3.7846],
		[1.2176,0.0000,1.7155]
	]
};

export default shapes;
