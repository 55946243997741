const lineData = [
	[[-0.7896,-3.0591],[-0.7896,-2.7583]],
	[[-0.8447,-3.0591],[-0.8447,-2.7564]],
	[[-0.8703,-3.0591],[-0.8703,-2.7583]],
	[[-0.9254,-3.0591],[-0.9254,-2.7564]],
	[[-0.9510,-3.0591],[-0.9510,-2.7583]],
	[[-1.0061,-3.0591],[-1.0061,-2.7564]],
	[[-1.0316,-3.0591],[-1.0316,-2.7583]],
	[[-1.0867,-3.0591],[-1.0867,-2.7564]],
	[[-1.1123,-3.0591],[-1.1123,-2.7583]],
	[[-1.1674,-3.0591],[-1.1674,-2.7564]],
	[[-1.1930,-3.0591],[-1.1930,-2.7583]],
	[[-1.2480,-3.0591],[-1.2480,-2.7564]],
	[[-1.2736,-3.0591],[-1.2736,-2.7564]],
	[[-1.3287,-3.0591],[-1.2736,-3.0591]],
	[[-1.3287,-3.0591],[-1.3287,-2.7564]],
	[[-1.3287,-3.0866],[-0.4670,-3.0866]],
	[[-1.3287,-3.1279],[-1.3287,-3.0866]],
	[[-1.3649,-2.6876],[-1.3649,-3.1279]],
	[[-0.4670,-2.7564],[-0.4670,-3.0571]],
	[[-0.5221,-2.7564],[-0.5221,-3.0591]],
	[[-0.5477,-2.7564],[-0.5477,-3.0571]],
	[[-0.6028,-2.7564],[-0.6028,-3.0591]],
	[[-0.6283,-2.7564],[-0.6283,-3.0571]],
	[[-0.6834,-2.7564],[-0.6834,-3.0591]],
	[[-0.7090,-2.7564],[-0.7090,-3.0571]],
	[[-0.7641,-2.7564],[-0.7641,-3.0591]],
	[[-0.7896,-2.7564],[-0.7896,-3.0571]],
	[[-0.8447,-2.7564],[-0.8447,-3.0591]],
	[[-0.8703,-2.7564],[-0.8703,-3.0571]],
	[[-0.9254,-2.7564],[-0.9254,-3.0591]],
	[[-0.9510,-2.7564],[-0.9510,-3.0571]],
	[[-1.0061,-2.7564],[-1.0061,-3.0591]],
	[[-1.0316,-2.7564],[-1.0316,-3.0571]],
	[[-1.0867,-2.7564],[-1.0867,-3.0591]],
	[[-1.1123,-2.7564],[-1.1123,-3.0571]],
	[[-1.1674,-2.7564],[-1.1674,-3.0591]],
	[[-1.1930,-2.7564],[-1.1930,-3.0571]],
	[[-1.2480,-2.7564],[-1.2480,-3.0591]],
	[[-1.2736,-2.7564],[-1.2736,-3.0571]],
	[[-1.3287,-2.7564],[-1.2736,-2.7564]],
	[[-1.3287,-2.7564],[-1.3287,-3.0591]],
	[[-1.3287,-2.7288],[-0.4670,-2.7288]],
	[[-1.3287,-2.6876],[-1.3287,-2.7288]],
	[[-1.3649,-2.6876],[-1.3287,-2.6876]],
	[[-1.3651,-3.1279],[-1.3651,-2.6876]],
	[[-1.3651,-3.2400],[-1.3651,-3.1279]],
	[[0.0001,-3.1279],[-0.2669,-3.1279]],
	[[0.0001,-4.6106],[-0.4195,-4.6106]],
	[[-1.3651,-3.2400],[-1.3651,-4.7382]],
	[[-1.1909,-3.2400],[-1.3117,-3.3608]],
	[[-1.3117,-3.4180],[-1.3117,-4.6106]],
	[[-1.1337,-3.2400],[-1.3117,-3.4180]],
	[[-0.5937,-3.2400],[-0.4729,-3.3608]],
	[[-0.4729,-3.4180],[-0.4729,-4.6106]],
	[[-0.6509,-3.2400],[-0.4729,-3.4180]],
	[[-0.2986,-3.2400],[-0.4195,-3.3608]],
	[[-0.4195,-3.4180],[-0.4195,-4.6106]],
	[[-0.2414,-3.2400],[-0.4195,-3.4180]],
	[[-1.3651,-3.2400],[-1.3651,-3.2400]],
	[[-1.3287,-3.1279],[-0.4670,-3.1279]],
	[[-0.2669,6.6701],[0.2670,6.6701]],
	[[0.0670,8.5279],[0.0001,8.5279]],
	[[-1.2715,6.8389],[-1.2715,7.0078]],
	[[-1.4053,6.8389],[-1.2715,6.8389]],
	[[-1.1376,7.0078],[-1.1376,7.1767]],
	[[-1.2715,7.0078],[-1.1376,7.0078]],
	[[-1.0038,7.1767],[-1.0038,7.3456]],
	[[-1.1376,7.1767],[-1.0038,7.1767]],
	[[-0.8699,7.3456],[-0.8699,7.5145]],
	[[-1.0038,7.3456],[-0.8699,7.3456]],
	[[-0.7361,7.5145],[-0.7361,7.6834]],
	[[-0.8699,7.5145],[-0.7361,7.5145]],
	[[-0.6022,7.6834],[-0.6022,7.8523]],
	[[-0.7361,7.6834],[-0.6022,7.6834]],
	[[-0.4684,7.8523],[-0.4684,8.0212]],
	[[-0.6022,7.8523],[-0.4684,7.8523]],
	[[-0.3346,8.0212],[-0.3346,8.1901]],
	[[-0.4684,8.0212],[-0.3346,8.0212]],
	[[-0.2007,8.1901],[-0.2007,8.3590]],
	[[-0.3346,8.1901],[-0.2007,8.1901]],
	[[-0.0669,8.3590],[-0.0669,8.5279]],
	[[-0.2007,8.3590],[-0.0669,8.3590]],
	[[-0.0669,8.5279],[0.0001,8.5279]],
	[[-1.5391,6.6701],[-1.4053,6.8389]],
	[[1.2716,6.8389],[1.2716,7.0078]],
	[[1.4054,6.8389],[1.2716,6.8389]],
	[[1.1377,7.0078],[1.1377,7.1767]],
	[[1.2716,7.0078],[1.1377,7.0078]],
	[[1.0039,7.1767],[1.0039,7.3456]],
	[[1.1377,7.1767],[1.0039,7.1767]],
	[[0.8701,7.3456],[0.8701,7.5145]],
	[[1.0039,7.3456],[0.8701,7.3456]],
	[[0.7362,7.5145],[0.7362,7.6834]],
	[[0.8701,7.5145],[0.7362,7.5145]],
	[[0.6024,7.6834],[0.6024,7.8523]],
	[[0.7362,7.6834],[0.6024,7.6834]],
	[[0.4685,7.8523],[0.4685,8.0212]],
	[[0.6024,7.8523],[0.4685,7.8523]],
	[[0.3347,8.0212],[0.3347,8.1901]],
	[[0.4685,8.0212],[0.3347,8.0212]],
	[[0.2008,8.1901],[0.2008,8.3590]],
	[[0.3347,8.1901],[0.2008,8.1901]],
	[[0.0670,8.3590],[0.0670,8.5279]],
	[[0.2008,8.3590],[0.0670,8.3590]],
	[[0.2670,6.6701],[1.5392,6.6701]],
	[[-1.5391,6.6701],[-0.2669,6.6701]],
	[[1.5392,6.6701],[1.4054,6.8389]],
	[[-1.4053,4.7423],[-1.4053,3.1321]],
	[[-1.2715,4.7423],[-1.4053,4.7423]],
	[[-1.2715,4.9112],[-1.2715,3.3010]],
	[[-1.1376,4.9112],[-1.2715,4.9112]],
	[[-1.1376,5.0801],[-1.1376,3.4699]],
	[[-1.0038,5.0801],[-1.1376,5.0801]],
	[[-1.0038,5.2490],[-1.0038,3.6388]],
	[[-0.8699,5.2490],[-1.0038,5.2490]],
	[[-0.8699,5.4179],[-0.8699,3.8077]],
	[[-0.7361,5.4179],[-0.8699,5.4179]],
	[[-0.7361,5.5868],[-0.7361,3.9766]],
	[[-0.6022,5.5868],[-0.7361,5.5868]],
	[[-0.6022,5.7557],[-0.6022,4.1455]],
	[[-0.4684,5.7557],[-0.6022,5.7557]],
	[[-0.4684,5.9246],[-0.4684,4.3144]],
	[[-0.3346,5.9246],[-0.4684,5.9246]],
	[[-0.3346,6.0935],[-0.3346,4.4833]],
	[[-0.2007,6.0935],[-0.3346,6.0935]],
	[[-0.2007,6.2624],[-0.2007,4.6522]],
	[[-0.0669,6.2624],[-0.2007,6.2624]],
	[[-0.0669,4.6522],[-0.0669,6.2624]],
	[[0.0670,4.8211],[0.0001,4.8211]],
	[[0.0670,6.4313],[0.0670,4.8211]],
	[[-0.0669,6.4313],[0.0670,6.4313]],
	[[-0.0669,4.8211],[-0.0669,6.4313]],
	[[-1.2715,3.1321],[-1.2715,3.3010]],
	[[-1.4053,3.1321],[-1.2715,3.1321]],
	[[-1.1376,3.3010],[-1.1376,3.4699]],
	[[-1.2715,3.3010],[-1.1376,3.3010]],
	[[-1.0038,3.4699],[-1.0038,3.6388]],
	[[-1.1376,3.4699],[-1.0038,3.4699]],
	[[-0.8699,3.6388],[-0.8699,3.8077]],
	[[-1.0038,3.6388],[-0.8699,3.6388]],
	[[-0.7361,3.8077],[-0.7361,3.9766]],
	[[-0.8699,3.8077],[-0.7361,3.8077]],
	[[-0.6022,3.9766],[-0.6022,4.1455]],
	[[-0.7361,3.9766],[-0.6022,3.9766]],
	[[-0.4684,4.1455],[-0.4684,4.3144]],
	[[-0.6022,4.1455],[-0.4684,4.1455]],
	[[-0.3346,4.3144],[-0.3346,4.4833]],
	[[-0.4684,4.3144],[-0.3346,4.3144]],
	[[-0.2007,4.4833],[-0.2007,4.6522]],
	[[-0.3346,4.4833],[-0.2007,4.4833]],
	[[-0.0669,4.6522],[-0.0669,4.8211]],
	[[-0.2007,4.6522],[-0.0669,4.6522]],
	[[-0.0669,4.8211],[0.0001,4.8211]],
	[[-1.5391,2.9633],[-1.4053,3.1321]],
	[[1.4054,4.7423],[1.4054,3.1321]],
	[[1.2716,4.7423],[1.4054,4.7423]],
	[[1.2716,4.9112],[1.2716,3.3010]],
	[[1.1377,4.9112],[1.2716,4.9112]],
	[[1.1377,5.0801],[1.1377,3.4699]],
	[[1.0039,5.0801],[1.1377,5.0801]],
	[[1.0039,5.2490],[1.0039,3.6388]],
	[[0.8701,5.2490],[1.0039,5.2490]],
	[[0.8701,5.4179],[0.8701,3.8077]],
	[[0.7362,5.4179],[0.8701,5.4179]],
	[[0.7362,5.5868],[0.7362,3.9766]],
	[[0.6024,5.5868],[0.7362,5.5868]],
	[[0.6024,5.7557],[0.6024,4.1455]],
	[[0.4685,5.7557],[0.6024,5.7557]],
	[[0.4685,5.9246],[0.4685,4.3144]],
	[[0.3347,5.9246],[0.4685,5.9246]],
	[[0.3347,6.0935],[0.3347,4.4833]],
	[[0.2008,6.0935],[0.3347,6.0935]],
	[[0.2008,6.2624],[0.2008,4.6522]],
	[[0.0670,6.2624],[0.2008,6.2624]],
	[[0.0670,4.6522],[0.0670,6.2624]],
	[[-0.0669,6.4313],[-0.0669,4.8211]],
	[[0.0670,6.4313],[-0.0669,6.4313]],
	[[0.0670,4.8211],[0.0670,6.4313]],
	[[1.2716,3.1321],[1.2716,3.3010]],
	[[1.4054,3.1321],[1.2716,3.1321]],
	[[1.1377,3.3010],[1.1377,3.4699]],
	[[1.2716,3.3010],[1.1377,3.3010]],
	[[1.0039,3.4699],[1.0039,3.6388]],
	[[1.1377,3.4699],[1.0039,3.4699]],
	[[0.8701,3.6388],[0.8701,3.8077]],
	[[1.0039,3.6388],[0.8701,3.6388]],
	[[0.7362,3.8077],[0.7362,3.9766]],
	[[0.8701,3.8077],[0.7362,3.8077]],
	[[0.6024,3.9766],[0.6024,4.1455]],
	[[0.7362,3.9766],[0.6024,3.9766]],
	[[0.4685,4.1455],[0.4685,4.3144]],
	[[0.6024,4.1455],[0.4685,4.1455]],
	[[0.3347,4.3144],[0.3347,4.4833]],
	[[0.4685,4.3144],[0.3347,4.3144]],
	[[0.2008,4.4833],[0.2008,4.6522]],
	[[0.3347,4.4833],[0.2008,4.4833]],
	[[0.0670,4.6522],[0.0670,4.8211]],
	[[0.2008,4.6522],[0.0670,4.6522]],
	[[0.2670,2.9633],[1.5392,2.9633]],
	[[-1.5391,2.9633],[-0.2669,2.9633]],
	[[0.0001,4.0671],[0.2670,4.0671]],
	[[0.2670,4.0671],[0.2670,2.9633]],
	[[1.5392,2.9633],[1.4054,3.1321]],
	[[0.0001,4.0671],[-0.2669,4.0671]],
	[[-0.2669,4.0671],[-0.2669,2.9633]],
	[[-2.9458,-1.6102],[-4.5560,0.0000]],
	[[-2.9458,-1.6102],[-2.9458,0.0000]],
	[[-2.9458,0.0000],[-3.7509,-0.8051]],
	[[-4.5560,0.0000],[-2.9458,0.0000]],
	[[-4.5560,0.1921],[-3.6756,1.0725]],
	[[-4.9560,0.0000],[-3.3458,-1.6102]],
	[[-4.9560,0.0000],[-4.9560,-1.6102]],
	[[-4.5560,2.1344],[-4.5560,0.1921]],
	[[-2.9458,0.1921],[-4.5560,0.1921]],
	[[-4.9560,-1.6102],[-4.1509,-0.8051]],
	[[-4.5560,2.1344],[-2.9458,0.1921]],
	[[-3.3458,-1.6102],[-4.9560,-1.6102]],
	[[2.9459,-1.6102],[4.5561,0.0000]],
	[[2.9459,-1.6102],[2.9459,0.0000]],
	[[2.9459,0.0000],[3.7510,-0.8051]],
	[[4.5561,0.0000],[2.9459,0.0000]],
	[[4.5561,0.1921],[3.6758,1.0725]],
	[[4.9561,0.0000],[3.3459,-1.6102]],
	[[4.9561,0.0000],[4.9561,-1.6102]],
	[[4.5561,2.1344],[4.5561,0.1921]],
	[[2.9459,0.1921],[4.5561,0.1921]],
	[[4.9561,-1.6102],[4.1510,-0.8051]],
	[[4.5561,2.1344],[2.9459,0.1921]],
	[[3.3459,-1.6102],[4.9561,-1.6102]],
	[[-2.7603,-0.3640],[-2.7603,-1.4826]],
	[[-2.7603,-0.3640],[-2.3031,-0.3640]],
	[[0.0001,1.1038],[-0.2669,1.1038]],
	[[-0.2669,1.1038],[-0.2669,0.0001]],
	[[-0.2113,1.0042],[-0.2113,0.0001]],
	[[0.0001,1.0042],[-0.2113,1.0042]],
	[[0.0001,0.0001],[-0.2669,0.0001]],
	[[-1.2579,-0.3118],[-1.2579,-0.7641]],
	[[-0.7292,-0.3118],[-0.7292,-0.7641]],
	[[-0.7292,-0.7550],[-0.7292,-0.7550]],
	[[-0.2669,-0.3118],[-0.2669,-1.4826]],
	[[0.0001,-0.4466],[-0.2113,-0.4466]],
	[[-0.2113,-0.3785],[0.0001,-0.3785]],
	[[-0.2113,-0.4466],[-0.2113,-0.3785]],
	[[-0.2113,-0.4785],[-0.2113,-1.4826]],
	[[0.0001,-0.4785],[-0.2113,-0.4785]],
	[[0.0001,-1.4826],[-0.2669,-1.4826]],
	[[-0.2669,-0.3118],[0.0001,-0.3118]],
	[[-2.8464,-1.6102],[0.0001,-1.6102]],
	[[-1.2661,0.0001],[0.0001,0.0001]],
	[[0.0001,1.6097],[-1.2661,0.0001]],
	[[-1.5391,0.0001],[0.0001,1.9423]],
	[[-2.8464,0.0001],[-1.5391,0.0001]],
	[[-2.8464,-0.1120],[-2.8464,0.0001]],
	[[-2.8464,-1.6102],[-2.8464,-0.1120]],
	[[-0.9355,-0.7731],[-1.0516,-0.7731]],
	[[-1.0516,-0.9432],[-1.0516,-0.7731]],
	[[-0.9355,-0.9432],[-1.0516,-0.9432]],
	[[-0.9355,-0.7731],[-0.9355,-0.9432]],
	[[-1.0516,-0.7731],[-0.9355,-0.7731]],
	[[-1.1857,-0.9432],[-1.1857,-0.7731]],
	[[-1.0697,-0.9432],[-1.1857,-0.9432]],
	[[-1.0697,-0.7731],[-1.0697,-0.9432]],
	[[-1.1857,-0.7731],[-1.0697,-0.7731]],
	[[-0.9355,-0.9432],[-0.9355,-0.7731]],
	[[-1.0516,-0.9432],[-0.9355,-0.9432]],
	[[-1.0516,-0.7731],[-1.0516,-0.9432]],
	[[-0.8013,-0.9432],[-0.8013,-0.7731]],
	[[-0.9174,-0.9432],[-0.8013,-0.9432]],
	[[-0.9174,-0.7731],[-0.9174,-0.9432]],
	[[-0.8013,-0.7731],[-0.9174,-0.7731]],
	[[-1.1857,-0.7731],[-1.1857,-0.9432]],
	[[-1.0697,-0.7731],[-1.1857,-0.7731]],
	[[-1.0697,-0.9432],[-1.0697,-0.7731]],
	[[-1.1857,-0.9432],[-1.0697,-0.9432]],
	[[-0.9355,-0.7911],[-0.9355,-0.9432]],
	[[-1.0516,-0.9432],[-1.0516,-0.7911]],
	[[-0.9355,-0.9612],[-1.0516,-0.9612]],
	[[-0.8013,-0.7731],[-0.8013,-0.9432]],
	[[-0.9174,-0.7731],[-0.8013,-0.7731]],
	[[-0.9174,-0.9432],[-0.9174,-0.7731]],
	[[-0.8013,-0.9432],[-0.9174,-0.9432]],
	[[-1.0516,-1.1313],[-1.0516,-0.9612]],
	[[-0.9355,-1.1313],[-1.0516,-1.1313]],
	[[-0.9355,-0.9612],[-0.9355,-1.1313]],
	[[-1.0516,-0.9612],[-0.9355,-0.9612]],
	[[-1.1857,-1.1313],[-1.1857,-0.9612]],
	[[-1.0697,-1.1313],[-1.1857,-1.1313]],
	[[-1.0697,-0.9612],[-1.0697,-1.1313]],
	[[-1.1857,-0.9612],[-1.0697,-0.9612]],
	[[-0.9355,-1.1313],[-0.9355,-0.9612]],
	[[-1.0516,-1.1313],[-0.9355,-1.1313]],
	[[-1.0516,-0.9612],[-1.0516,-1.1313]],
	[[-0.8013,-1.1313],[-0.8013,-0.9612]],
	[[-0.9174,-1.1313],[-0.8013,-1.1313]],
	[[-0.9174,-0.9612],[-0.9174,-1.1313]],
	[[-0.8013,-0.9612],[-0.9174,-0.9612]],
	[[-0.9355,-0.7550],[-1.0516,-0.7550]],
	[[-1.0516,-0.5849],[-1.0516,-0.7550]],
	[[-0.9355,-0.5849],[-1.0516,-0.5849]],
	[[-0.9355,-0.7550],[-0.9355,-0.5849]],
	[[-1.0516,-0.7550],[-0.9355,-0.7550]],
	[[-1.1857,-0.5849],[-1.1857,-0.7550]],
	[[-1.0697,-0.5849],[-1.1857,-0.5849]],
	[[-1.0697,-0.7550],[-1.0697,-0.5849]],
	[[-1.1857,-0.7550],[-1.0697,-0.7550]],
	[[-0.9355,-0.5849],[-0.9355,-0.7550]],
	[[-1.0516,-0.5849],[-0.9355,-0.5849]],
	[[-1.0516,-0.7550],[-1.0516,-0.5849]],
	[[-0.8013,-0.5849],[-0.8013,-0.7550]],
	[[-0.9174,-0.5849],[-0.8013,-0.5849]],
	[[-0.9174,-0.7550],[-0.9174,-0.5849]],
	[[-0.8013,-0.7550],[-0.9174,-0.7550]],
	[[-1.1857,-0.7550],[-1.1857,-0.5849]],
	[[-1.0697,-0.7550],[-1.1857,-0.7550]],
	[[-1.0697,-0.5849],[-1.0697,-0.7550]],
	[[-1.1857,-0.5849],[-1.0697,-0.5849]],
	[[-0.9355,-0.7370],[-0.9355,-0.5849]],
	[[-1.0516,-0.5849],[-1.0516,-0.7370]],
	[[-0.9355,-0.5669],[-1.0516,-0.5669]],
	[[-0.8013,-0.7550],[-0.8013,-0.5849]],
	[[-0.9174,-0.7550],[-0.8013,-0.7550]],
	[[-0.9174,-0.5849],[-0.9174,-0.7550]],
	[[-0.8013,-0.5849],[-0.9174,-0.5849]],
	[[-1.0516,-0.3968],[-1.0516,-0.5669]],
	[[-0.9355,-0.3968],[-0.9935,-0.3968]],
	[[-0.9355,-0.5669],[-0.9355,-0.3968]],
	[[-1.0516,-0.5669],[-0.9355,-0.5669]],
	[[-1.1857,-0.3968],[-1.1857,-0.5669]],
	[[-1.0697,-0.3968],[-1.1857,-0.3968]],
	[[-1.0697,-0.5669],[-1.0697,-0.3968]],
	[[-1.1857,-0.5669],[-1.0697,-0.5669]],
	[[-0.9355,-0.3968],[-0.9355,-0.5669]],
	[[-1.0516,-0.3968],[-0.9355,-0.3968]],
	[[-1.0516,-0.5669],[-1.0516,-0.3968]],
	[[-0.8013,-0.3968],[-0.8013,-0.5669]],
	[[-0.9174,-0.3968],[-0.8013,-0.3968]],
	[[-0.9174,-0.5669],[-0.9174,-0.3968]],
	[[-0.8013,-0.5669],[-0.9174,-0.5669]],
	[[-1.7743,-0.3118],[-2.0387,-0.3118]],
	[[-1.7743,-1.2163],[-2.3031,-1.2163]],
	[[-2.3031,-1.2163],[-2.3031,-0.7641]],
	[[-1.7743,-1.2163],[-1.7743,-0.7641]],
	[[-1.9807,-0.7731],[-2.0967,-0.7731]],
	[[-2.0967,-0.9432],[-2.0967,-0.7731]],
	[[-1.9807,-0.9432],[-2.0967,-0.9432]],
	[[-1.9807,-0.7731],[-1.9807,-0.9432]],
	[[-2.0967,-0.7731],[-1.9807,-0.7731]],
	[[-2.2309,-0.9432],[-2.2309,-0.7731]],
	[[-2.1149,-0.9432],[-2.2309,-0.9432]],
	[[-2.1149,-0.7731],[-2.1149,-0.9432]],
	[[-2.2309,-0.7731],[-2.1149,-0.7731]],
	[[-1.9807,-0.9432],[-1.9807,-0.7731]],
	[[-2.0967,-0.9432],[-1.9807,-0.9432]],
	[[-2.0967,-0.7731],[-2.0967,-0.9432]],
	[[-1.8465,-0.9432],[-1.8465,-0.7731]],
	[[-1.9626,-0.9432],[-1.8465,-0.9432]],
	[[-1.9626,-0.7731],[-1.9626,-0.9432]],
	[[-1.8465,-0.7731],[-1.9626,-0.7731]],
	[[-2.2309,-0.7731],[-2.2309,-0.9432]],
	[[-2.1149,-0.7731],[-2.2309,-0.7731]],
	[[-2.1149,-0.9432],[-2.1149,-0.7731]],
	[[-2.2309,-0.9432],[-2.1149,-0.9432]],
	[[-1.9807,-0.7911],[-1.9807,-0.9432]],
	[[-2.0967,-0.9432],[-2.0967,-0.7911]],
	[[-1.9807,-0.9612],[-2.0967,-0.9612]],
	[[-1.8465,-0.7731],[-1.8465,-0.9432]],
	[[-1.9626,-0.7731],[-1.8465,-0.7731]],
	[[-1.9626,-0.9432],[-1.9626,-0.7731]],
	[[-1.8465,-0.9432],[-1.9626,-0.9432]],
	[[-2.0967,-1.1313],[-2.0967,-0.9612]],
	[[-1.9807,-1.1313],[-2.0967,-1.1313]],
	[[-1.9807,-0.9612],[-1.9807,-1.1313]],
	[[-2.0967,-0.9612],[-1.9807,-0.9612]],
	[[-2.2309,-1.1313],[-2.2309,-0.9612]],
	[[-2.1149,-1.1313],[-2.2309,-1.1313]],
	[[-2.1149,-0.9612],[-2.1149,-1.1313]],
	[[-2.2309,-0.9612],[-2.1149,-0.9612]],
	[[-1.9807,-1.1313],[-1.9807,-0.9612]],
	[[-2.0967,-1.1313],[-1.9807,-1.1313]],
	[[-2.0967,-0.9612],[-2.0967,-1.1313]],
	[[-1.8465,-1.1313],[-1.8465,-0.9612]],
	[[-1.9626,-1.1313],[-1.8465,-1.1313]],
	[[-1.9626,-0.9612],[-1.9626,-1.1313]],
	[[-1.8465,-0.9612],[-1.9626,-0.9612]],
	[[-1.7743,-0.7731],[-1.7743,-0.7731]],
	[[-2.0387,-0.3118],[-2.3031,-0.3118]],
	[[-2.3031,-0.3118],[-2.3031,-0.7641]],
	[[-1.7743,-0.3118],[-1.7743,-0.7641]],
	[[-1.9807,-0.7550],[-2.0967,-0.7550]],
	[[-2.0967,-0.5849],[-2.0967,-0.7550]],
	[[-1.9807,-0.5849],[-2.0967,-0.5849]],
	[[-1.9807,-0.7550],[-1.9807,-0.5849]],
	[[-2.0967,-0.7550],[-1.9807,-0.7550]],
	[[-2.2309,-0.5849],[-2.2309,-0.7550]],
	[[-2.1149,-0.5849],[-2.2309,-0.5849]],
	[[-2.1149,-0.7550],[-2.1149,-0.5849]],
	[[-2.2309,-0.7550],[-2.1149,-0.7550]],
	[[-1.9807,-0.5849],[-1.9807,-0.7550]],
	[[-2.0967,-0.5849],[-1.9807,-0.5849]],
	[[-2.0967,-0.7550],[-2.0967,-0.5849]],
	[[-1.8465,-0.5849],[-1.8465,-0.7550]],
	[[-1.9626,-0.5849],[-1.8465,-0.5849]],
	[[-1.9626,-0.7550],[-1.9626,-0.5849]],
	[[-1.8465,-0.7550],[-1.9626,-0.7550]],
	[[-2.2309,-0.7550],[-2.2309,-0.5849]],
	[[-2.1149,-0.7550],[-2.2309,-0.7550]],
	[[-2.1149,-0.5849],[-2.1149,-0.7550]],
	[[-2.2309,-0.5849],[-2.1149,-0.5849]],
	[[-1.9807,-0.7370],[-1.9807,-0.5849]],
	[[-2.0967,-0.5849],[-2.0967,-0.7370]],
	[[-1.9807,-0.5669],[-2.0967,-0.5669]],
	[[-1.8465,-0.7550],[-1.8465,-0.5849]],
	[[-1.9626,-0.7550],[-1.8465,-0.7550]],
	[[-1.9626,-0.5849],[-1.9626,-0.7550]],
	[[-1.8465,-0.5849],[-1.9626,-0.5849]],
	[[-2.0967,-0.3968],[-2.0967,-0.5669]],
	[[-1.9807,-0.3968],[-2.0387,-0.3968]],
	[[-1.9807,-0.5669],[-1.9807,-0.3968]],
	[[-2.0967,-0.5669],[-1.9807,-0.5669]],
	[[-2.2309,-0.3968],[-2.2309,-0.5669]],
	[[-2.1149,-0.3968],[-2.2309,-0.3968]],
	[[-2.1149,-0.5669],[-2.1149,-0.3968]],
	[[-2.2309,-0.5669],[-2.1149,-0.5669]],
	[[-1.9807,-0.3968],[-1.9807,-0.5669]],
	[[-2.0967,-0.3968],[-1.9807,-0.3968]],
	[[-2.0967,-0.5669],[-2.0967,-0.3968]],
	[[-1.8465,-0.3968],[-1.8465,-0.5669]],
	[[-1.9626,-0.3968],[-1.8465,-0.3968]],
	[[-1.9626,-0.5669],[-1.9626,-0.3968]],
	[[-1.8465,-0.5669],[-1.9626,-0.5669]],
	[[-1.7743,-0.7550],[-1.7743,-0.7550]],
	[[-0.7292,-1.2163],[-1.2579,-1.2163]],
	[[-1.2579,-1.2163],[-1.2579,-0.7641]],
	[[-0.7292,-1.2163],[-0.7292,-0.7641]],
	[[-0.7292,-0.7731],[-0.7292,-0.7731]],
	[[-0.7292,-0.3118],[-1.2579,-0.3118]],
	[[-1.3651,-0.3640],[-1.2579,-0.3640]],
	[[-1.7743,-0.3640],[-1.3651,-0.3640]],
	[[2.0969,-0.7731],[1.9808,-0.7731]],
	[[1.9808,-0.9432],[1.9808,-0.7731]],
	[[2.0969,-0.9432],[1.9808,-0.9432]],
	[[2.0969,-0.7731],[2.0969,-0.9432]],
	[[1.9808,-0.7731],[2.0969,-0.7731]],
	[[1.8466,-0.9432],[1.8466,-0.7731]],
	[[1.9627,-0.9432],[1.8466,-0.9432]],
	[[1.9627,-0.7731],[1.9627,-0.9432]],
	[[1.8466,-0.7731],[1.9627,-0.7731]],
	[[2.0969,-0.9432],[2.0969,-0.7731]],
	[[1.9808,-0.9432],[2.0969,-0.9432]],
	[[1.9808,-0.7731],[1.9808,-0.9432]],
	[[2.2310,-0.9432],[2.2310,-0.7731]],
	[[2.1150,-0.9432],[2.2310,-0.9432]],
	[[2.1150,-0.7731],[2.1150,-0.9432]],
	[[2.2310,-0.7731],[2.1150,-0.7731]],
	[[1.8466,-0.7731],[1.8466,-0.9432]],
	[[1.9627,-0.7731],[1.8466,-0.7731]],
	[[1.9627,-0.9432],[1.9627,-0.7731]],
	[[1.8466,-0.9432],[1.9627,-0.9432]],
	[[2.0969,-0.7911],[2.0969,-0.9432]],
	[[1.9808,-0.9432],[1.9808,-0.7911]],
	[[2.0969,-0.9612],[1.9808,-0.9612]],
	[[2.2310,-0.7731],[2.2310,-0.9432]],
	[[2.1150,-0.7731],[2.2310,-0.7731]],
	[[2.1150,-0.9432],[2.1150,-0.7731]],
	[[2.2310,-0.9432],[2.1150,-0.9432]],
	[[1.9808,-1.1313],[1.9808,-0.9612]],
	[[2.0969,-1.1313],[1.9808,-1.1313]],
	[[2.0969,-0.9612],[2.0969,-1.1313]],
	[[1.9808,-0.9612],[2.0969,-0.9612]],
	[[1.8466,-1.1313],[1.8466,-0.9612]],
	[[1.9627,-1.1313],[1.8466,-1.1313]],
	[[1.9627,-0.9612],[1.9627,-1.1313]],
	[[1.8466,-0.9612],[1.9627,-0.9612]],
	[[2.0969,-1.1313],[2.0969,-0.9612]],
	[[1.9808,-1.1313],[2.0969,-1.1313]],
	[[1.9808,-0.9612],[1.9808,-1.1313]],
	[[2.2310,-1.1313],[2.2310,-0.9612]],
	[[2.1150,-1.1313],[2.2310,-1.1313]],
	[[2.1150,-0.9612],[2.1150,-1.1313]],
	[[2.2310,-0.9612],[2.1150,-0.9612]],
	[[2.0969,-0.7550],[1.9808,-0.7550]],
	[[1.9808,-0.5849],[1.9808,-0.7550]],
	[[2.0969,-0.5849],[1.9808,-0.5849]],
	[[2.0969,-0.7550],[2.0969,-0.5849]],
	[[1.9808,-0.7550],[2.0969,-0.7550]],
	[[1.8466,-0.5849],[1.8466,-0.7550]],
	[[1.9627,-0.5849],[1.8466,-0.5849]],
	[[1.9627,-0.7550],[1.9627,-0.5849]],
	[[1.8466,-0.7550],[1.9627,-0.7550]],
	[[2.0969,-0.5849],[2.0969,-0.7550]],
	[[1.9808,-0.5849],[2.0969,-0.5849]],
	[[1.9808,-0.7550],[1.9808,-0.5849]],
	[[2.2310,-0.5849],[2.2310,-0.7550]],
	[[2.1150,-0.5849],[2.2310,-0.5849]],
	[[2.1150,-0.7550],[2.1150,-0.5849]],
	[[2.2310,-0.7550],[2.1150,-0.7550]],
	[[1.8466,-0.7550],[1.8466,-0.5849]],
	[[1.9627,-0.7550],[1.8466,-0.7550]],
	[[1.9627,-0.5849],[1.9627,-0.7550]],
	[[1.8466,-0.5849],[1.9627,-0.5849]],
	[[2.0969,-0.7370],[2.0969,-0.5849]],
	[[1.9808,-0.5849],[1.9808,-0.7370]],
	[[2.0969,-0.5669],[1.9808,-0.5669]],
	[[2.2310,-0.7550],[2.2310,-0.5849]],
	[[2.1150,-0.7550],[2.2310,-0.7550]],
	[[2.1150,-0.5849],[2.1150,-0.7550]],
	[[2.2310,-0.5849],[2.1150,-0.5849]],
	[[1.9808,-0.3968],[1.9808,-0.5669]],
	[[2.0969,-0.3968],[2.0388,-0.3968]],
	[[2.0969,-0.5669],[2.0969,-0.3968]],
	[[1.9808,-0.5669],[2.0969,-0.5669]],
	[[1.8466,-0.3968],[1.8466,-0.5669]],
	[[1.9627,-0.3968],[1.8466,-0.3968]],
	[[1.9627,-0.5669],[1.9627,-0.3968]],
	[[1.8466,-0.5669],[1.9627,-0.5669]],
	[[2.0969,-0.3968],[2.0969,-0.5669]],
	[[1.9808,-0.3968],[2.0969,-0.3968]],
	[[1.9808,-0.5669],[1.9808,-0.3968]],
	[[2.2310,-0.3968],[2.2310,-0.5669]],
	[[2.1150,-0.3968],[2.2310,-0.3968]],
	[[2.1150,-0.5669],[2.1150,-0.3968]],
	[[2.2310,-0.5669],[2.1150,-0.5669]],
	[[1.0517,-0.7731],[0.9356,-0.7731]],
	[[0.9356,-0.9432],[0.9356,-0.7731]],
	[[1.0517,-0.9432],[0.9356,-0.9432]],
	[[1.0517,-0.7731],[1.0517,-0.9432]],
	[[0.9356,-0.7731],[1.0517,-0.7731]],
	[[0.8015,-0.9432],[0.8015,-0.7731]],
	[[0.9175,-0.9432],[0.8015,-0.9432]],
	[[0.9175,-0.7731],[0.9175,-0.9432]],
	[[0.8015,-0.7731],[0.9175,-0.7731]],
	[[1.0517,-0.9432],[1.0517,-0.7731]],
	[[0.9356,-0.9432],[1.0517,-0.9432]],
	[[0.9356,-0.7731],[0.9356,-0.9432]],
	[[1.1859,-0.9432],[1.1859,-0.7731]],
	[[1.0698,-0.9432],[1.1859,-0.9432]],
	[[1.0698,-0.7731],[1.0698,-0.9432]],
	[[1.1859,-0.7731],[1.0698,-0.7731]],
	[[0.8015,-0.7731],[0.8015,-0.9432]],
	[[0.9175,-0.7731],[0.8015,-0.7731]],
	[[0.9175,-0.9432],[0.9175,-0.7731]],
	[[0.8015,-0.9432],[0.9175,-0.9432]],
	[[1.0517,-0.7911],[1.0517,-0.9432]],
	[[0.9356,-0.9432],[0.9356,-0.7911]],
	[[1.0517,-0.9612],[0.9356,-0.9612]],
	[[1.1859,-0.7731],[1.1859,-0.9432]],
	[[1.0698,-0.7731],[1.1859,-0.7731]],
	[[1.0698,-0.9432],[1.0698,-0.7731]],
	[[1.1859,-0.9432],[1.0698,-0.9432]],
	[[0.9356,-1.1313],[0.9356,-0.9612]],
	[[1.0517,-1.1313],[0.9356,-1.1313]],
	[[1.0517,-0.9612],[1.0517,-1.1313]],
	[[0.9356,-0.9612],[1.0517,-0.9612]],
	[[0.8015,-1.1313],[0.8015,-0.9612]],
	[[0.9175,-1.1313],[0.8015,-1.1313]],
	[[0.9175,-0.9612],[0.9175,-1.1313]],
	[[0.8015,-0.9612],[0.9175,-0.9612]],
	[[1.0517,-1.1313],[1.0517,-0.9612]],
	[[0.9356,-1.1313],[1.0517,-1.1313]],
	[[0.9356,-0.9612],[0.9356,-1.1313]],
	[[1.1859,-1.1313],[1.1859,-0.9612]],
	[[1.0698,-1.1313],[1.1859,-1.1313]],
	[[1.0698,-0.9612],[1.0698,-1.1313]],
	[[1.1859,-0.9612],[1.0698,-0.9612]],
	[[1.0517,-0.7550],[0.9356,-0.7550]],
	[[0.9356,-0.5849],[0.9356,-0.7550]],
	[[1.0517,-0.5849],[0.9356,-0.5849]],
	[[1.0517,-0.7550],[1.0517,-0.5849]],
	[[0.9356,-0.7550],[1.0517,-0.7550]],
	[[0.8015,-0.5849],[0.8015,-0.7550]],
	[[0.9175,-0.5849],[0.8015,-0.5849]],
	[[0.9175,-0.7550],[0.9175,-0.5849]],
	[[0.8015,-0.7550],[0.9175,-0.7550]],
	[[1.0517,-0.5849],[1.0517,-0.7550]],
	[[0.9356,-0.5849],[1.0517,-0.5849]],
	[[0.9356,-0.7550],[0.9356,-0.5849]],
	[[1.1859,-0.5849],[1.1859,-0.7550]],
	[[1.0698,-0.5849],[1.1859,-0.5849]],
	[[1.0698,-0.7550],[1.0698,-0.5849]],
	[[1.1859,-0.7550],[1.0698,-0.7550]],
	[[0.8015,-0.7550],[0.8015,-0.5849]],
	[[0.9175,-0.7550],[0.8015,-0.7550]],
	[[0.9175,-0.5849],[0.9175,-0.7550]],
	[[0.8015,-0.5849],[0.9175,-0.5849]],
	[[1.0517,-0.7370],[1.0517,-0.5849]],
	[[0.9356,-0.5849],[0.9356,-0.7370]],
	[[1.0517,-0.5669],[0.9356,-0.5669]],
	[[1.1859,-0.7550],[1.1859,-0.5849]],
	[[1.0698,-0.7550],[1.1859,-0.7550]],
	[[1.0698,-0.5849],[1.0698,-0.7550]],
	[[1.1859,-0.5849],[1.0698,-0.5849]],
	[[0.9356,-0.3968],[0.9356,-0.5669]],
	[[1.0517,-0.3968],[0.9937,-0.3968]],
	[[1.0517,-0.5669],[1.0517,-0.3968]],
	[[0.9356,-0.5669],[1.0517,-0.5669]],
	[[0.8015,-0.3968],[0.8015,-0.5669]],
	[[0.9175,-0.3968],[0.8015,-0.3968]],
	[[0.9175,-0.5669],[0.9175,-0.3968]],
	[[0.8015,-0.5669],[0.9175,-0.5669]],
	[[1.0517,-0.3968],[1.0517,-0.5669]],
	[[0.9356,-0.3968],[1.0517,-0.3968]],
	[[0.9356,-0.5669],[0.9356,-0.3968]],
	[[1.1859,-0.3968],[1.1859,-0.5669]],
	[[1.0698,-0.3968],[1.1859,-0.3968]],
	[[1.0698,-0.5669],[1.0698,-0.3968]],
	[[1.1859,-0.5669],[1.0698,-0.5669]],
	[[1.7745,-1.2163],[2.3032,-1.2163]],
	[[2.3032,-1.2163],[2.3032,-0.7641]],
	[[1.7745,-1.2163],[1.7745,-0.7641]],
	[[1.7745,-0.7731],[1.7745,-0.7731]],
	[[1.7745,-0.3118],[2.3032,-0.3118]],
	[[2.3032,-0.3118],[2.3032,-0.7641]],
	[[1.7745,-0.3118],[1.7745,-0.7641]],
	[[1.7745,-0.7550],[1.7745,-0.7550]],
	[[0.7293,-1.2163],[1.2580,-1.2163]],
	[[1.2580,-1.2163],[1.2580,-0.7641]],
	[[0.7293,-1.2163],[0.7293,-0.7641]],
	[[0.7293,-0.7731],[0.7293,-0.7731]],
	[[0.7293,-0.3118],[1.2580,-0.3118]],
	[[1.3653,-0.3640],[1.2580,-0.3640]],
	[[1.7745,-0.3640],[1.3653,-0.3640]],
	[[1.2580,-0.3118],[1.2580,-0.7641]],
	[[0.7293,-0.3118],[0.7293,-0.7641]],
	[[0.7293,-0.7550],[0.7293,-0.7550]],
	[[1.5392,0.2218],[2.8465,0.2218]],
	[[-2.8464,0.2218],[-1.5391,0.2218]],
	[[0.0001,2.7448],[1.5392,0.2218]],
	[[2.8465,2.7448],[0.0001,2.7448]],
	[[2.8465,0.2218],[2.8465,2.7448]],
	[[0.0001,2.7448],[-1.5391,0.2218]],
	[[-2.8464,2.7448],[0.0001,2.7448]],
	[[-2.8464,0.2218],[-2.8464,2.7448]],
	[[0.0001,0.6384],[0.0540,0.6384]],
	[[0.0540,0.3551],[0.0001,0.3551]],
	[[0.0540,0.6384],[0.0540,0.3551]],
	[[0.0001,0.9406],[0.0540,0.9406]],
	[[0.0540,0.6572],[0.0001,0.6572]],
	[[0.0540,0.9406],[0.0540,0.6572]],
	[[0.0759,0.6384],[0.1213,0.6384]],
	[[0.0759,0.3551],[0.0759,0.6384]],
	[[0.1213,0.3551],[0.0759,0.3551]],
	[[0.1213,0.6384],[0.1213,0.3551]],
	[[0.0759,0.9406],[0.1213,0.9406]],
	[[0.0759,0.6572],[0.0759,0.9406]],
	[[0.1213,0.6572],[0.0759,0.6572]],
	[[0.1213,0.9406],[0.1213,0.6572]],
	[[0.0001,1.1038],[0.2670,1.1038]],
	[[0.2670,1.1038],[0.2670,0.0001]],
	[[0.2114,1.0042],[0.2114,0.0001]],
	[[0.0001,1.0042],[0.2114,1.0042]],
	[[0.0001,0.0001],[0.2670,0.0001]],
	[[1.2662,0.0001],[0.0001,0.0001]],
	[[0.0001,1.6097],[1.2662,0.0001]],
	[[1.5392,0.0001],[0.0001,1.9423]],
	[[2.8465,0.0001],[1.5392,0.0001]],
	[[2.8465,-0.1120],[2.8465,0.0001]],
	[[0.0001,0.6384],[-0.0538,0.6384]],
	[[-0.0538,0.3551],[0.0001,0.3551]],
	[[-0.0538,0.6384],[-0.0538,0.3551]],
	[[0.0001,0.9406],[-0.0538,0.9406]],
	[[-0.0538,0.6572],[0.0001,0.6572]],
	[[-0.0538,0.9406],[-0.0538,0.6572]],
	[[-0.0758,0.6384],[-0.1212,0.6384]],
	[[-0.0758,0.3551],[-0.0758,0.6384]],
	[[-0.1212,0.3551],[-0.0758,0.3551]],
	[[-0.1212,0.6384],[-0.1212,0.3551]],
	[[-0.0758,0.9406],[-0.1212,0.9406]],
	[[-0.0758,0.6572],[-0.0758,0.9406]],
	[[-0.1212,0.6572],[-0.0758,0.6572]],
	[[-0.1212,0.9406],[-0.1212,0.6572]],
	[[-2.7603,-1.4826],[-1.3651,-1.4826]],
	[[0.4196,-0.3640],[0.4730,-0.3640]],
	[[-0.4729,-0.3640],[-0.4195,-0.3640]],
	[[0.4196,-1.4826],[0.4730,-1.4826]],
	[[-0.4729,-1.4826],[-0.4195,-1.4826]],
	[[-1.3384,-1.4826],[-0.4462,-1.4826]],
	[[-0.3645,-1.4826],[-0.3111,-1.4826]],
	[[0.4196,-0.3640],[0.2670,-0.3640]],
	[[1.3653,-1.4826],[0.0001,-1.4826]],
	[[0.2670,-0.3118],[0.2670,-1.4826]],
	[[0.0001,-0.4466],[0.2114,-0.4466]],
	[[0.2114,-0.3785],[0.0001,-0.3785]],
	[[0.2114,-0.4466],[0.2114,-0.3785]],
	[[0.2114,-0.4785],[0.2114,-1.4826]],
	[[0.0001,-0.4785],[0.2114,-0.4785]],
	[[0.0001,-1.4826],[0.2670,-1.4826]],
	[[0.2670,-0.3118],[0.0001,-0.3118]],
	[[2.8465,-1.6102],[0.0001,-1.6102]],
	[[-0.4195,-0.3640],[-0.2669,-0.3640]],
	[[-0.7292,-0.3640],[-0.4729,-0.3640]],
	[[-1.3651,-1.4826],[0.0001,-1.4826]],
	[[0.7293,-0.3640],[0.4730,-0.3640]],
	[[2.7604,-1.4826],[1.3653,-1.4826]],
	[[2.7604,-0.3640],[2.7604,-1.4826]],
	[[2.7604,-0.3640],[2.3032,-0.3640]],
	[[2.8465,-1.6102],[2.8465,-0.1120]],
	[[1.3653,-6.9300],[1.3653,-6.0320]],
	[[-1.3651,-6.9300],[1.3653,-6.9300]],
	[[-1.3651,-6.0320],[-1.3651,-6.9300]],
	[[-1.3651,-6.0320],[1.3653,-6.0320]],
	[[1.3653,-4.9835],[1.3653,-5.8814]],
	[[-1.3651,-4.9835],[1.3653,-4.9835]],
	[[-1.3651,-5.8814],[-1.3651,-4.9835]],
	[[1.3288,-5.8814],[1.2662,-5.8814]],
	[[1.3650,-5.8814],[1.3288,-5.8814]],
	[[1.2662,-5.8814],[0.0001,-5.8814]],
	[[-1.2661,-5.8814],[0.0001,-5.8814]],
	[[-1.3651,-5.8814],[-1.2661,-5.8814]],
	[[-2.8464,-8.8276],[2.8465,-8.8276]],
	[[2.8465,-7.2174],[2.8465,-8.8276]],
	[[-2.8464,-7.2174],[2.8465,-7.2174]],
	[[-2.8464,-8.8276],[-2.8464,-7.2174]],
	[[2.0142,-3.1279],[1.9777,-3.1279]],
	[[2.0142,-2.7288],[2.0142,-3.1279]],
	[[1.9777,-2.7288],[2.0142,-2.7288]],
	[[1.9777,-3.1279],[1.9413,-3.1279]],
	[[1.9777,-2.7288],[1.9777,-3.1279]],
	[[1.9413,-2.7288],[1.9777,-2.7288]],
	[[1.9413,-3.1279],[1.9048,-3.1279]],
	[[1.9413,-2.7288],[1.9413,-3.1279]],
	[[1.9048,-2.7288],[1.9413,-2.7288]],
	[[1.9048,-3.1279],[1.8684,-3.1279]],
	[[1.9048,-2.7288],[1.9048,-3.1279]],
	[[1.8684,-2.7288],[1.9048,-2.7288]],
	[[1.8684,-3.1279],[1.8319,-3.1279]],
	[[1.8684,-2.7288],[1.8684,-3.1279]],
	[[1.8319,-2.7288],[1.8684,-2.7288]],
	[[1.8319,-3.1279],[1.7955,-3.1279]],
	[[1.8319,-2.7288],[1.8319,-3.1279]],
	[[1.7955,-2.7288],[1.8319,-2.7288]],
	[[1.7955,-3.1279],[1.7590,-3.1279]],
	[[1.7955,-2.7288],[1.7955,-3.1279]],
	[[1.7590,-2.7288],[1.7955,-2.7288]],
	[[1.7590,-3.1279],[1.7226,-3.1279]],
	[[1.7590,-2.7288],[1.7590,-3.1279]],
	[[1.7226,-2.7288],[1.7590,-2.7288]],
	[[1.7226,-3.1279],[1.6861,-3.1279]],
	[[1.7226,-2.7288],[1.7226,-3.1279]],
	[[1.6861,-2.7288],[1.7226,-2.7288]],
	[[1.6861,-2.7288],[1.6861,-3.1279]],
	[[1.6861,-3.1279],[1.6497,-3.1279]],
	[[1.6497,-2.7288],[1.6861,-2.7288]],
	[[1.6497,-2.7288],[1.6497,-3.1279]],
	[[-2.0141,-3.1279],[-1.9776,-3.1279]],
	[[-2.0141,-2.7288],[-2.0141,-3.1279]],
	[[-1.9776,-2.7288],[-2.0141,-2.7288]],
	[[-1.9776,-3.1279],[-1.9412,-3.1279]],
	[[-1.9776,-2.7288],[-1.9776,-3.1279]],
	[[-1.9412,-2.7288],[-1.9776,-2.7288]],
	[[-1.9412,-3.1279],[-1.9047,-3.1279]],
	[[-1.9412,-2.7288],[-1.9412,-3.1279]],
	[[-1.9047,-2.7288],[-1.9412,-2.7288]],
	[[-1.9047,-3.1279],[-1.8683,-3.1279]],
	[[-1.9047,-2.7288],[-1.9047,-3.1279]],
	[[-1.8683,-2.7288],[-1.9047,-2.7288]],
	[[-1.8683,-3.1279],[-1.8318,-3.1279]],
	[[-1.8683,-2.7288],[-1.8683,-3.1279]],
	[[-1.8318,-2.7288],[-1.8683,-2.7288]],
	[[-1.8318,-3.1279],[-1.7954,-3.1279]],
	[[-1.8318,-2.7288],[-1.8318,-3.1279]],
	[[-1.7954,-2.7288],[-1.8318,-2.7288]],
	[[-1.7954,-3.1279],[-1.7589,-3.1279]],
	[[-1.7954,-2.7288],[-1.7954,-3.1279]],
	[[-1.7589,-2.7288],[-1.7954,-2.7288]],
	[[-1.7589,-3.1279],[-1.7225,-3.1279]],
	[[-1.7589,-2.7288],[-1.7589,-3.1279]],
	[[-1.7225,-2.7288],[-1.7589,-2.7288]],
	[[-1.7225,-3.1279],[-1.6860,-3.1279]],
	[[-1.7225,-2.7288],[-1.7225,-3.1279]],
	[[-1.6860,-2.7288],[-1.7225,-2.7288]],
	[[-1.6860,-2.7288],[-1.6860,-3.1279]],
	[[-1.6860,-3.1279],[-1.6496,-3.1279]],
	[[-1.6496,-2.7288],[-1.6860,-2.7288]],
	[[-1.6496,-2.7288],[-1.6496,-3.1279]],
	[[-0.2414,-3.2400],[0.2416,-3.2400]],
	[[-0.4195,-3.3608],[-0.4195,-3.2400]],
	[[-0.4729,-3.3608],[-0.4729,-3.2400]],
	[[-0.4195,-3.2400],[-0.2986,-3.2400]],
	[[-0.5937,-3.2400],[-0.4729,-3.2400]],
	[[-1.1337,-3.2400],[-0.6509,-3.2400]],
	[[-1.3117,-3.3608],[-1.3117,-3.2400]],
	[[0.0001,-3.1279],[0.4309,-3.1279]],
	[[-0.2669,-3.1279],[-0.4308,-3.1279]],
	[[-1.3287,-2.6463],[-1.3649,-2.6463]],
	[[-1.3649,-2.6463],[-1.3649,-2.6876]],
	[[-1.3287,-2.6463],[-1.3287,-2.6876]],
	[[-1.3285,-1.7484],[-1.3285,-2.6463]],
	[[-1.3649,-1.7484],[-1.3285,-1.7484]],
	[[-1.3649,-2.6463],[-1.3649,-1.7484]],
	[[-1.3651,-4.7382],[1.3653,-4.7382]],
	[[-1.1909,-3.2400],[-1.3117,-3.2400]],
	[[-1.3117,-4.6106],[-0.4729,-4.6106]],
	[[0.0276,-3.0591],[0.0001,-3.0591]],
	[[0.1083,-3.0591],[0.0532,-3.0591]],
	[[0.0276,-2.7564],[0.0001,-2.7564]],
	[[0.1083,-2.7564],[0.0532,-2.7564]],
	[[0.0276,-3.0591],[0.0276,-2.7564]],
	[[0.0532,-3.0591],[0.0532,-2.7583]],
	[[0.4309,-3.0866],[0.0001,-3.0866]],
	[[0.0276,-2.7564],[0.0276,-3.0591]],
	[[0.0532,-2.7564],[0.0532,-3.0571]],
	[[0.4309,-2.7288],[0.0001,-2.7288]],
	[[0.0001,-4.6106],[0.2670,-4.6106]],
	[[-0.1888,-3.0591],[-0.1337,-3.0591]],
	[[-0.2695,-3.0591],[-0.2144,-3.0591]],
	[[-0.4308,-2.7564],[-0.3757,-2.7564]],
	[[-0.0275,-3.0591],[0.0001,-3.0591]],
	[[-0.1081,-3.0591],[-0.0531,-3.0591]],
	[[-0.2950,-3.0591],[-0.3501,-3.0591]],
	[[-0.0275,-2.7564],[0.0001,-2.7564]],
	[[-0.1081,-2.7564],[-0.0531,-2.7564]],
	[[-0.1888,-2.7564],[-0.1337,-2.7564]],
	[[-0.2695,-2.7564],[-0.2144,-2.7564]],
	[[-0.3501,-2.7564],[-0.2950,-2.7564]],
	[[-0.0275,-3.0591],[-0.0275,-2.7564]],
	[[-0.0531,-3.0591],[-0.0531,-2.7583]],
	[[-0.1081,-3.0591],[-0.1081,-2.7564]],
	[[-0.1337,-3.0591],[-0.1337,-2.7583]],
	[[-0.1888,-3.0591],[-0.1888,-2.7564]],
	[[-0.2144,-3.0591],[-0.2144,-2.7583]],
	[[-0.2695,-3.0591],[-0.2695,-2.7564]],
	[[-0.2950,-3.0591],[-0.2950,-2.7583]],
	[[-0.3501,-3.0591],[-0.3501,-2.7564]],
	[[-0.3757,-3.0591],[-0.3757,-2.7564]],
	[[-0.4308,-3.0591],[-0.3757,-3.0591]],
	[[-0.4308,-3.0591],[-0.4308,-2.7564]],
	[[-0.4308,-3.0866],[0.0001,-3.0866]],
	[[-0.4308,-3.1279],[-0.4308,-3.0866]],
	[[-0.0275,-2.7564],[-0.0275,-3.0591]],
	[[-0.0531,-2.7564],[-0.0531,-3.0571]],
	[[-0.1081,-2.7564],[-0.1081,-3.0591]],
	[[-0.1337,-2.7564],[-0.1337,-3.0571]],
	[[-0.1888,-2.7564],[-0.1888,-3.0591]],
	[[-0.2144,-2.7564],[-0.2144,-3.0571]],
	[[-0.2695,-2.7564],[-0.2695,-3.0591]],
	[[-0.2950,-2.7564],[-0.2950,-3.0571]],
	[[-0.3501,-2.7564],[-0.3501,-3.0591]],
	[[-0.3757,-2.7564],[-0.3757,-3.0571]],
	[[-0.4308,-2.7564],[-0.4308,-3.0591]],
	[[-0.4308,-2.7288],[0.0001,-2.7288]],
	[[-0.4308,-2.6876],[-0.4308,-2.7288]],
	[[-0.4670,-2.6876],[-0.4308,-2.6876]],
	[[-1.0867,-3.0591],[-1.0316,-3.0591]],
	[[-1.1674,-3.0591],[-1.1123,-3.0591]],
	[[-0.6834,-2.7564],[-0.6283,-2.7564]],
	[[-0.7641,-2.7564],[-0.7090,-2.7564]],
	[[-0.4670,-3.0591],[-0.4670,-2.7564]],
	[[-0.4670,-3.1279],[-0.4670,-3.0866]],
	[[-0.4670,-2.6876],[-0.4670,-2.7288]],
	[[-0.4308,-2.6876],[-0.4670,-2.6876]],
	[[-0.5221,-3.0591],[-0.4670,-3.0591]],
	[[-0.6028,-3.0591],[-0.5477,-3.0591]],
	[[-0.6834,-3.0591],[-0.6283,-3.0591]],
	[[-0.7641,-3.0591],[-0.7090,-3.0591]],
	[[-0.8447,-3.0591],[-0.7896,-3.0591]],
	[[-0.9254,-3.0591],[-0.8703,-3.0591]],
	[[-1.0061,-3.0591],[-0.9510,-3.0591]],
	[[-1.1930,-3.0591],[-1.2480,-3.0591]],
	[[-0.5221,-2.7564],[-0.4670,-2.7564]],
	[[-0.6028,-2.7564],[-0.5477,-2.7564]],
	[[-0.8447,-2.7564],[-0.7896,-2.7564]],
	[[-0.9254,-2.7564],[-0.8703,-2.7564]],
	[[-1.0061,-2.7564],[-0.9510,-2.7564]],
	[[-1.0867,-2.7564],[-1.0316,-2.7564]],
	[[-1.1674,-2.7564],[-1.1123,-2.7564]],
	[[-1.2480,-2.7564],[-1.1930,-2.7564]],
	[[-0.4670,-3.0591],[-0.4670,-2.7583]],
	[[-0.5221,-3.0591],[-0.5221,-2.7564]],
	[[-0.5477,-3.0591],[-0.5477,-2.7583]],
	[[-0.6028,-3.0591],[-0.6028,-2.7564]],
	[[-0.6283,-3.0591],[-0.6283,-2.7583]],
	[[-0.6834,-3.0591],[-0.6834,-2.7564]],
	[[-0.7090,-3.0591],[-0.7090,-2.7583]],
	[[-0.7641,-3.0591],[-0.7641,-2.7564]],
	[[0.4730,-4.6106],[1.3119,-4.6106]],
	[[0.2670,-4.6106],[0.4196,-4.6106]],
	[[1.3119,-3.3608],[1.3119,-3.2400]],
	[[0.4730,-3.3608],[0.4730,-3.2400]],
	[[0.4196,-3.3608],[0.4196,-3.2400]],
	[[1.1910,-3.2400],[1.3119,-3.2400]],
	[[0.6510,-3.2400],[1.1338,-3.2400]],
	[[0.5938,-3.2400],[0.4730,-3.2400]],
	[[0.2988,-3.2400],[0.4196,-3.2400]],
	[[1.2662,-3.1279],[1.3288,-3.1279]],
	[[1.3288,-2.6463],[1.3650,-2.6463]],
	[[1.3650,-2.6463],[1.3650,-2.6876]],
	[[1.3288,-2.6463],[1.3288,-2.6876]],
	[[1.3286,-1.7484],[1.3286,-2.6463]],
	[[1.3650,-1.7484],[1.3286,-1.7484]],
	[[1.3650,-2.6463],[1.3650,-1.7484]],
	[[0.5938,-3.2400],[0.4730,-3.3608]],
	[[0.4730,-3.4180],[0.4730,-4.6106]],
	[[0.6510,-3.2400],[0.4730,-3.4180]],
	[[0.2988,-3.2400],[0.4196,-3.3608]],
	[[0.4196,-3.4180],[0.4196,-4.6106]],
	[[0.2416,-3.2400],[0.4196,-3.4180]],
	[[0.1889,-3.0591],[0.1338,-3.0591]],
	[[0.2696,-3.0591],[0.2145,-3.0591]],
	[[0.4309,-2.7564],[0.3758,-2.7564]],
	[[0.2952,-3.0591],[0.3502,-3.0591]],
	[[0.1889,-2.7564],[0.1338,-2.7564]],
	[[0.2696,-2.7564],[0.2145,-2.7564]],
	[[0.3502,-2.7564],[0.2952,-2.7564]],
	[[0.1083,-3.0591],[0.1083,-2.7564]],
	[[0.1338,-3.0591],[0.1338,-2.7583]],
	[[0.1889,-3.0591],[0.1889,-2.7564]],
	[[0.2145,-3.0591],[0.2145,-2.7583]],
	[[0.2696,-3.0591],[0.2696,-2.7564]],
	[[0.2952,-3.0591],[0.2952,-2.7583]],
	[[0.3502,-3.0591],[0.3502,-2.7564]],
	[[0.3758,-3.0591],[0.3758,-2.7564]],
	[[0.4309,-3.0591],[0.3758,-3.0591]],
	[[0.4309,-3.0591],[0.4309,-2.7564]],
	[[0.4309,-3.1279],[0.4309,-3.0866]],
	[[0.1083,-2.7564],[0.1083,-3.0591]],
	[[0.1338,-2.7564],[0.1338,-3.0571]],
	[[0.1889,-2.7564],[0.1889,-3.0591]],
	[[0.2145,-2.7564],[0.2145,-3.0571]],
	[[0.2696,-2.7564],[0.2696,-3.0591]],
	[[0.2952,-2.7564],[0.2952,-3.0571]],
	[[0.3502,-2.7564],[0.3502,-3.0591]],
	[[0.3758,-2.7564],[0.3758,-3.0571]],
	[[0.4309,-2.7564],[0.4309,-3.0591]],
	[[0.4309,-2.6876],[0.4309,-2.7288]],
	[[0.4671,-2.6876],[0.4309,-2.6876]],
	[[1.0868,-3.0591],[1.0318,-3.0591]],
	[[1.1675,-3.0591],[1.1124,-3.0591]],
	[[1.3288,-2.7564],[1.2737,-2.7564]],
	[[0.6835,-2.7564],[0.6285,-2.7564]],
	[[0.7642,-2.7564],[0.7091,-2.7564]],
	[[0.4671,-3.0591],[0.4671,-2.7564]],
	[[0.4671,-3.1279],[0.4671,-3.0866]],
	[[0.4671,-2.6876],[0.4671,-2.7288]],
	[[0.4309,-2.6876],[0.4671,-2.6876]],
	[[0.5222,-3.0591],[0.4671,-3.0591]],
	[[0.6029,-3.0591],[0.5478,-3.0591]],
	[[0.6835,-3.0591],[0.6285,-3.0591]],
	[[0.7642,-3.0591],[0.7091,-3.0591]],
	[[0.8449,-3.0591],[0.7898,-3.0591]],
	[[0.9255,-3.0591],[0.8704,-3.0591]],
	[[1.0062,-3.0591],[0.9511,-3.0591]],
	[[1.1931,-3.0591],[1.2482,-3.0591]],
	[[0.5222,-2.7564],[0.4671,-2.7564]],
	[[0.6029,-2.7564],[0.5478,-2.7564]],
	[[0.8449,-2.7564],[0.7898,-2.7564]],
	[[0.9255,-2.7564],[0.8704,-2.7564]],
	[[1.0062,-2.7564],[0.9511,-2.7564]],
	[[1.0868,-2.7564],[1.0318,-2.7564]],
	[[1.1675,-2.7564],[1.1124,-2.7564]],
	[[1.2482,-2.7564],[1.1931,-2.7564]],
	[[0.4671,-3.0591],[0.4671,-2.7583]],
	[[0.5222,-3.0591],[0.5222,-2.7564]],
	[[0.5478,-3.0591],[0.5478,-2.7583]],
	[[0.6029,-3.0591],[0.6029,-2.7564]],
	[[0.6285,-3.0591],[0.6285,-2.7583]],
	[[0.6835,-3.0591],[0.6835,-2.7564]],
	[[0.7091,-3.0591],[0.7091,-2.7583]],
	[[0.7642,-3.0591],[0.7642,-2.7564]],
	[[0.7898,-3.0591],[0.7898,-2.7583]],
	[[0.8449,-3.0591],[0.8449,-2.7564]],
	[[0.8704,-3.0591],[0.8704,-2.7583]],
	[[0.9255,-3.0591],[0.9255,-2.7564]],
	[[0.9511,-3.0591],[0.9511,-2.7583]],
	[[1.0062,-3.0591],[1.0062,-2.7564]],
	[[1.0318,-3.0591],[1.0318,-2.7583]],
	[[1.0868,-3.0591],[1.0868,-2.7564]],
	[[1.1124,-3.0591],[1.1124,-2.7583]],
	[[1.1675,-3.0591],[1.1675,-2.7564]],
	[[1.1931,-3.0591],[1.1931,-2.7583]],
	[[1.2482,-3.0591],[1.2482,-2.7564]],
	[[1.2737,-3.0591],[1.2737,-2.7564]],
	[[1.3288,-3.0591],[1.2737,-3.0591]],
	[[1.3288,-3.0591],[1.3288,-2.7564]],
	[[1.3288,-3.0866],[0.4671,-3.0866]],
	[[1.3288,-3.1279],[1.3288,-3.0866]],
	[[1.3650,-2.6876],[1.3650,-3.1279]],
	[[0.4671,-2.7564],[0.4671,-3.0571]],
	[[0.5222,-2.7564],[0.5222,-3.0591]],
	[[0.5478,-2.7564],[0.5478,-3.0571]],
	[[0.6029,-2.7564],[0.6029,-3.0591]],
	[[0.6285,-2.7564],[0.6285,-3.0571]],
	[[0.6835,-2.7564],[0.6835,-3.0591]],
	[[0.7091,-2.7564],[0.7091,-3.0571]],
	[[0.7642,-2.7564],[0.7642,-3.0591]],
	[[0.7898,-2.7564],[0.7898,-3.0571]],
	[[0.8449,-2.7564],[0.8449,-3.0591]],
	[[0.8704,-2.7564],[0.8704,-3.0571]],
	[[0.9255,-2.7564],[0.9255,-3.0591]],
	[[0.9511,-2.7564],[0.9511,-3.0571]],
	[[1.0062,-2.7564],[1.0062,-3.0591]],
	[[1.0318,-2.7564],[1.0318,-3.0571]],
	[[1.0868,-2.7564],[1.0868,-3.0591]],
	[[1.1124,-2.7564],[1.1124,-3.0571]],
	[[1.1675,-2.7564],[1.1675,-3.0591]],
	[[1.1931,-2.7564],[1.1931,-3.0571]],
	[[1.2482,-2.7564],[1.2482,-3.0591]],
	[[1.2737,-2.7564],[1.2737,-3.0571]],
	[[1.3288,-2.7564],[1.3288,-3.0591]],
	[[1.3288,-2.7288],[0.4671,-2.7288]],
	[[1.3288,-2.6876],[1.3288,-2.7288]],
	[[1.3650,-2.6876],[1.3288,-2.6876]],
	[[1.3653,-3.1279],[1.3653,-2.6876]],
	[[1.3653,-3.2400],[1.3653,-3.1279]],
	[[1.3653,-3.2400],[1.3653,-4.7382]],
	[[1.1910,-3.2400],[1.3119,-3.3608]],
	[[1.3119,-3.4180],[1.3119,-4.6106]],
	[[1.1338,-3.2400],[1.3119,-3.4180]],
	[[1.3653,-3.2400],[1.3653,-3.2400]],
	[[1.2662,-3.1279],[0.4671,-3.1279]],
];

export default lineData;
